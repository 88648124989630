import React, { useState } from 'react';
import ORDERING_PURCHASE_ORDERS from '@fingo/lib/graphql/queries/ordering-purchase-orders';
import { DocumentList } from '@fingo/lib/components/lists';
import { useOrderingLucilaPreColumns } from '@fingo/lib/constants';
import OrderingOffersHeader from './headers/OrderingOffersHeader';

const OrderingOffersManager = () => {
  const [selectedPurchaseOrderIds, setSelectedPurchaseOrderIds] = useState([]);
  return (
    <DocumentList
      trackerId="ORDERING_PREOFFER"
      type="ordering"
      headerTitle="Ofertas de ordering"
      queryDocument={ORDERING_PURCHASE_ORDERS}
      emitter
      customVariables={{
        inOffer: true,
        companyId: null,
      }}
      includeHeaders={[
        'orderNumber',
        'company_MasterEntity_Name',
        'purchaser_Name',
        'publicationDate',
        'totalAmount',
      ]}
      mobileHeaders={[
        'orderNumber',
        'purchaser_Name',
        'totalAmount',
        'orderingsimulation_OrderingMonthlyRate',
        'orderingInvoiceDate',
      ]}
      showFilters
      checkboxSelection
      selectionModel={selectedPurchaseOrderIds}
      onSelectionModelChange={(ids) => setSelectedPurchaseOrderIds(ids)}
      defaultFilterProps={{
        showStatesFilter: false,
        showDatesFilter: false,
        showCompanyIssuerFilter: true,
        defaultFilterDays: 30,
      }}
      flexEndButtons={() => (
        <OrderingOffersHeader
          selectedPurchaseOrderIds={selectedPurchaseOrderIds}
        />
      )}
      onCompletedSetLength={(data) => data.purchaseOrders.totalCount}
      onCompletedSetRows={(data) => data.purchaseOrders.edges.map((edge) => edge.node)}
      preColumns={useOrderingLucilaPreColumns()}
      initialOrderBy="-publicationDate"
    />
  );
};

export default OrderingOffersManager;
