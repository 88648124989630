const groupRelatedFormMasterEntities = (masterEntity) => {
  // Group master entity and Related Legal representative entities
  // to show all related buro forms in one datagrid
  const {
    buroauthorizationformdocumentSet, company, ...restMasterEntity
  } = masterEntity;
  const legalRepresentative = company?.legalRepresentative;
  const legalRepresentativeMasterEntity = legalRepresentative?.map((rep) => rep.relatedPerson);
  const formsArray = buroauthorizationformdocumentSet.map((doc) => (
    { ...doc, masterEntity: restMasterEntity }
  ));
  const legalRepresentativesForms = legalRepresentativeMasterEntity?.reduce((acc, item) => {
    const { buroauthorizationformdocumentSet: repDocsSet, ...restEntity } = item;
    return acc.concat(repDocsSet.map((repDoc) => ({ ...repDoc, masterEntity: restEntity })));
  }, []) ?? [];
  return formsArray.concat(legalRepresentativesForms);
};

export default groupRelatedFormMasterEntities;
