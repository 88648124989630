import { gql } from '@apollo/client';
import {
  sharedInvoiceInputFields,
  sharedInvoiceInput,
  TypeConnectionFields,
} from '@fingo/lib/graphql/fragments';

import {
  InvoiceIdFields,
  InvoiceStatusFields,
  CreditNoteFields,
  InvoiceDteTypeFields,
  TraceFields,
  DocumentFinanceStateFields,
  InvoiceRequestingPlatformFields,
} from '@fingo/lib/graphql/invoices/fragments';

import { RiskBlacklistFields, MasterEntityBasicField } from '@fingo/lib/graphql/customers/fragment';
import { CollectionActionsFields, CollectionPriorityFields, CollectionManagerBasicFields, DataForCollectionFields } from '@fingo/lib/graphql/collection/fragments';
import { UserIdentification } from '@fingo/lib/graphql/user/fragments';
import { FundBasicField, InvoiceLoanRosterBasic, InvoiceLoanManagerBasicField } from '@fingo/lib/graphql/invoice-loan/fragments';

export const COLLECTION_SUMMARY = gql`
  query CollectionSummary($ownerId: ID!, $product: String, $currency: String) {
    factoringCollectionManagersByPriority(ownerId: $ownerId, product: $product, currency: $currency) {
      count
      amount
      debtors
      collectionPriority
    }
    factoringCollectionManagersByExpiration(ownerId: $ownerId, product: $product, currency: $currency) {
      unexpiredAmount
      expiredAmount
      unexpiredCount
      expiredCount
    }
    factoringCollectionManagersByDateRange(ownerId: $ownerId, product: $product, currency: $currency) {
      dateGroup
      values {
        amount
        count
        priority
      }
    }
    orderingCollectionManagersByPriority(ownerId: $ownerId, currency: $currency) {
      count
      amount
      debtors
      collectionPriority
    }
    orderingCollectionManagersByExpiration(ownerId: $ownerId, currency: $currency) {
      expiredAmount
      unexpiredAmount
    }
    orderingCollectionManagersByDateRange(ownerId: $ownerId, currency: $currency) {
      dateGroup
      values {
        amount
        count
        priority
      }
    }
  }
`;

export const LUCILA_COLLECTION_INVOICES = gql`
  query lucilaInvoicesInCollection(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ... InvoiceIdFields
          ... InvoiceStatusFields
          ... CreditNoteFields
          ... InvoiceRequestingPlatformFields
          isFunding @client
          lightningPaymentAuthorized @client
          isRatificated
          hasPaymentsEmitted
          dteType {
            ... InvoiceDteTypeFields
          }
          traces {
            ... TraceFields
          }
          uniqueDocumentFinanceState {
            ... DocumentFinanceStateFields
          }
          company {
            id
            masterEntity {
              ... MasterEntityBasicField
              riskBlacklist {
                ...RiskBlacklistFields
              }
            }
            executiveAssigned {
              ... UserIdentification
            }
          }
          receiver {
            ... MasterEntityBasicField
            riskBlacklist {
              ...RiskBlacklistFields
            }
          }
          invoiceloanmanager {
            ... InvoiceLoanManagerBasicField
            invoiceLoanRoster {
              ... InvoiceLoanRosterBasic
              fund {
                ... FundBasicField
                masterEntity {
                  ... MasterEntityBasicField
                }
              }
            }
          }
          collectionManager {
            ... CollectionManagerBasicFields
            owner {
              id
            }
            collectionPriority {
              ... CollectionPriorityFields
            }
            amountActions
            lastActionCreatedAt
            collector {
              ... UserIdentification
            }
            currentDataForCollection {
              ... DataForCollectionFields
            }
          }
          ratificationmanager {
            id
            actions {
              id
              actionType
              comment
              createdAt
              channel
              responded
              author {
                ... UserIdentification
              }
            }
          }
          invoiceDebt {
            id
            days
             debt {
              ...MoneyFields
             }
          }
        }
      }
    }
  }
  ${TypeConnectionFields}
  ${InvoiceIdFields}
  ${InvoiceStatusFields}
  ${CollectionManagerBasicFields}
  ${DataForCollectionFields}
  ${CollectionPriorityFields}
  ${CreditNoteFields}
  ${InvoiceDteTypeFields}
  ${TraceFields}
  ${DocumentFinanceStateFields}
  ${MasterEntityBasicField}
  ${InvoiceLoanManagerBasicField}
  ${InvoiceLoanRosterBasic}
  ${FundBasicField}
  ${UserIdentification}
  ${RiskBlacklistFields}
  ${InvoiceRequestingPlatformFields}
`;

export const LUCILA_INVOICE_COLLECTION_ACTIONS = gql`
  query invoiceCollectionActions($invoiceId: ID!) {
    invoice(invoiceId: $invoiceId) {
      id
      collectionManager {
        id
        actions {
          ... CollectionActionsFields 
          author {
            ... UserIdentification
          }
        }
      }
    }
  }
  ${CollectionActionsFields}
  ${UserIdentification}
`;
