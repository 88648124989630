import React, { useCallback, useState } from 'react';
import { DocumentList } from '@fingo/lib/components/lists';
import { useOrderingLucilaPreColumns, MOBILE_HEADERS } from '@fingo/lib/constants';
import ORDERING_PURCHASE_ORDERS from '@fingo/lib/graphql/queries/ordering-purchase-orders';
import { PURCHASE_ORDERS_COLUMNS } from '../../constants/treasury';
import TransfersSummary from './TransfersSummary';

const PurchaseOrderTransfersList = () => {
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);
  const cleanDocuments = useCallback(() => {
    setSelectedDocumentIds([]);
  }, []);

  return (
    <DocumentList
      trackerId="Transfers-Ordering"
      type="ordering-transfer-panel"
      headerTitle="Órdenes de Compra por girar"
      queryDocument={ORDERING_PURCHASE_ORDERS}
      includeHeaders={PURCHASE_ORDERS_COLUMNS}
      mobileHeaders={MOBILE_HEADERS}
      showExportInvoice
      preColumns={useOrderingLucilaPreColumns()}
      onSelectionModelChange={(ids) => setSelectedDocumentIds(ids)}
      flexEndButtons={() => (
        <TransfersSummary
          selectedDocumentIds={selectedDocumentIds}
          cleanDocuments={cleanDocuments}
          modelLabel="purchaseorder"
        />
      )}
      defaultFilterProps={{
        showStatesFilter: false,
        showDatesFilter: false,
        showRefresh: true,
        showCompanyIssuerFilter: false,
      }}
      onCompletedSetLength={(data) => data.purchaseOrders.totalCount}
      onCompletedSetRows={(data) => data.purchaseOrders.edges.map((edge) => (edge.node))}
      showFilters
      selectionModel={selectedDocumentIds}
      checkboxSelection
      customVariables={{
        allIssuedCompany: false,
        availableForTransfer: true,
      }}
      density="comfortable"
      initialOrderBy="company_MasterEntity_Name"
    />
  );
};

export default PurchaseOrderTransfersList;
