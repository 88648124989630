import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes, { arrayOf } from 'prop-types';
import React, { useState } from 'react';
import RiskRestrictionsDialog from '../../../../treasury/components/transfers/dialogs/RiskRestrictionsDialog';

const AcceptEvaluationRequestsForm = ({
  company,
  companyRestrictions,
  acceptEvaluationRequests,
  analystDescription,
  setAnalystDescription,
  columns,
  disabled,
  sendEmail,
  setSendEmail,
  loading,
  error,
  rows,
  resolveRestrictionResolutionRefetchQueries,
}) => {
  const [openRiskRestrictionsDialog, setOpenRiskRestrictionsDialog] = useState(false);
  return (
    <>
      <FingoDataGrid
        hideFooter
        rowHeight={180}
        rows={rows}
        columns={columns}
      />
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item>
          <Stack direction="row" alignItems="center">
            <Typography>Enviar Email de Aceptación</Typography>
            <Checkbox
              color="primary"
              checked={sendEmail}
              onChange={() => setSendEmail(!sendEmail)}
            />
          </Stack>
        </Grid>
        <Grid item sx={{ width: '70%' }}>
          <TextField
            sx={{ width: '100%' }}
            variant="outlined"
            name="acceptComments"
            label="Comentarios de Aprobación"
            value={analystDescription}
            onChange={(e) => setAnalystDescription(e.target.value)}
            multiline
            rows={3}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setOpenRiskRestrictionsDialog(true)}
            disabled={companyRestrictions.length === 0}
          >
            {(companyRestrictions.length === 0) ? 'La Empresa no tiene' : ''} Restricciones
          </Button>
        </Grid>
        <Grid item>
          <LoadingButton
            variant="contained"
            color={!error ? 'primary' : 'warning'}
            size="small"
            sx={{ marginRight: 1 }}
            disabled={disabled}
            onClick={acceptEvaluationRequests}
            loading={loading}
          >
            {error ? 'Error al' : ''} Confirmar Aprobación
          </LoadingButton>
        </Grid>
      </Grid>
      <RiskRestrictionsDialog
        open={openRiskRestrictionsDialog}
        handleClose={setOpenRiskRestrictionsDialog}
        companyId={company?.id}
        resolveRestrictionResolutionRefetchQueries={resolveRestrictionResolutionRefetchQueries}
      />
    </>
  );
};

AcceptEvaluationRequestsForm.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  companyRestrictions: arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      restriction: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
  acceptEvaluationRequests: PropTypes.func.isRequired,
  analystDescription: PropTypes.string.isRequired,
  setAnalystDescription: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  sendEmail: PropTypes.bool.isRequired,
  setSendEmail: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      companyId: PropTypes.string.isRequired,
    }),
  ).isRequired,
  resolveRestrictionResolutionRefetchQueries: PropTypes.arrayOf(PropTypes.string),
};

AcceptEvaluationRequestsForm.defaultProps = {
  resolveRestrictionResolutionRefetchQueries: [],
};

export default AcceptEvaluationRequestsForm;
