import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { TextField, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useSnackBars } from '@fingo/lib/hooks';
import { RESOLVE_CONTRACT_UPLOAD_REQUEST } from '@fingo/lib/graphql';

const ApproveContractDialog = ({
  open,
  toggleOpen,
  contractUploadRequestId,
}) => {
  const [comment, setComment] = useState('');
  const { addAlert } = useSnackBars();

  const handleClose = () => {
    toggleOpen(false);
    setComment('');
  };

  const [uploadMutation, { loading }] = useMutation(RESOLVE_CONTRACT_UPLOAD_REQUEST, {
    variables: {
      approved: null,
      contractUploadRequestId,
      comment,
    },
    onCompleted: handleClose,
    onError: (err) => addAlert({
      id: 'approve-contract-error',
      message: `Error uploading contract: ${err.message}`,
    }),
  });

  const handleApprove = () => {
    uploadMutation({
      variables: {
        approved: true,
      },
    });
  };

  const handleReject = () => {
    uploadMutation({
      variables: {
        approved: false,
      },
    });
  };

  return (
    <FingoDialog
      title="Aprobar contrato"
      open={open}
      handleClose={handleClose}
      dialogActionButton={(
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
          <LoadingButton
            size="medium"
            color="success"
            variant="contained"
            onClick={handleApprove}
            loading={loading}
          >
            Aprobar
          </LoadingButton>
          <LoadingButton
            size="medium"
            color="error"
            variant="contained"
            onClick={handleReject}
            loading={loading}
          >
            Rechazar
          </LoadingButton>
        </Box>
      )}
    >
      <Box width={500} sx={{ mb: 2 }}>
        <TextField
          label="Comentario"
          fullWidth
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          multiline
          rows={4}
          placeholder="Escribe tu comentario aquí..."
        />
      </Box>
    </FingoDialog>
  );
};

ApproveContractDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  contractUploadRequestId: PropTypes.string.isRequired,
};

export default ApproveContractDialog;
