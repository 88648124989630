import React from 'react';
import LucilaInvoiceProfile from '../components/invoice-profile-manager/LucilaInvoiceProfile';

const InvoiceProfileManagerTabs = () => [
  {
    id: 'invoice-profile',
    label: 'Perfil Factura',
    path: 'invoice-profile',
    icon: null,
    component: <LucilaInvoiceProfile />,
    disabled: false,
    show: true,
  },
];

export default InvoiceProfileManagerTabs;
