import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { shoppingCartOffersVar } from '@fingo/lib/apollo/reactive-variables/shopping-cart';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import useInvoicePreColumns from '@fingo/lib/constants/invoice-pre-columns';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import dayjs from '@fingo/lib/config/dayjs';
import DateToPayEditCell from '@fingo/lib/components/dataGridCells/DateToPayEditCell';

const COLUMNS = [
  'folio',
  'dateIssued',
  'amountWithIva',
  'orderingMonthlyRate',
  'orderingDefaultRate',
  'dateToPay',
];

const AddInvoicesToPurchaseOrdersDialogStepOne = (props) => {
  const {
    statistics,
    offers,
    loading,
    selectedOfferIds,
    setSelectedOfferIds,
    setOrderingAssignmentRequest,
    page,
    setPage,
    pageSize,
    setPageSize,
    totalCount,
  } = props;

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized && offers?.length > 0) {
      const initialOffers = offers.map((invoice) => {
        const initializedOffer = { invoiceId: invoice.id };
        if (
          invoice.dateToPay && dayjs(invoice.dateToPay, 'YYYY-MM-DD', true).isValid()
        ) {
          initializedOffer.dateToPay = dayjs(invoice.dateToPay).format('YYYY-MM-DD');
        } else {
          initializedOffer.dateToPay = null;
        }
        return initializedOffer;
      });
      shoppingCartOffersVar(initialOffers);
      setInitialized(true);
    }
  }, [offers, initialized]);

  const currentOffers = useReactiveVar(shoppingCartOffersVar);

  useEffect(() => {
    const updatedAssignmentRequest = currentOffers
      .filter(
        (invoice) => invoice.dateToPay && selectedOfferIds.includes(invoice.invoiceId),
      )
      .map(({ invoiceId, dateToPay }) => ({
        invoiceId,
        dateToPay: dayjs(dateToPay).format('YYYY-MM-DD'),
      }));
    setOrderingAssignmentRequest(updatedAssignmentRequest);
  }, [currentOffers]);

  const columns = useInvoicePreColumns()
    .filter((col) => COLUMNS.includes(col.field))
    .map((col) => {
      if (col.field === 'dateToPay') {
        return {
          ...col,
          renderEditCell: (params) => (
            <DateToPayEditCell
              params={params}
              selectedOfferIds={selectedOfferIds}
            />
          ),
          renderCell: (params) => (
            <DateToPayEditCell
              params={params}
              selectedOfferIds={selectedOfferIds}
            />
          ),
        };
      }
      return col;
    });

  return (
    <>
      <Stack
        direction="row"
        divider={
          <Divider orientation="vertical" flexItem sx={{ bgcolor: grey[50] }} />
        }
        spacing={3}
        bgcolor="gray.A100"
        mb={3}
        p={3}
        justifyContent="space-around"
      >
        {statistics.map(({ label, value, color }) => (
          <Box
            key={label}
            sx={{
              height: 70,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'space-around',
              justifyContent: 'space-around',
              textAlign: 'center',
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: 'bold', fontSize: 14 }}
            >
              {label}
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: 14, color: color || 'text' }}
            >
              {value}
            </Typography>
          </Box>
        ))}
      </Stack>

      <FingoDataGrid
        rows={offers}
        columns={columns}
        loadingWithSkeleton={loading}
        pagination
        paginationMode="server"
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowCount={totalCount}
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={(ids) => {
          setSelectedOfferIds(ids);
        }}
        selectionModel={selectedOfferIds}
        density="compact"
        boxHeight={370}
      />
    </>
  );
};

AddInvoicesToPurchaseOrdersDialogStepOne.propTypes = {
  statistics: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
    }),
  ).isRequired,
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  selectedOfferIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedOfferIds: PropTypes.func.isRequired,
  setOrderingAssignmentRequest: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
};

export default AddInvoicesToPurchaseOrdersDialogStepOne;
