import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import DocumentChip from '@fingo/lib/components/cells/DocumentsChip';
import UPLOAD_INVOICE_DOCUMENTS from '@fingo/lib/graphql/mutations/upload-invoice-documents';
import Grid from '@mui/material/Grid';
import AccountBalance from '@mui/icons-material/AccountBalance';
import Assignment from '@mui/icons-material/Assignment';
import TrendingUp from '@mui/icons-material/TrendingUp';
import Create from '@mui/icons-material/Create';

const getDocument = (code, documentList) => (
  documentList.find((document) => document.type.code === code)
);
const buildInvoiceDocuments = (
  { documents, offer },
) => [
  {
    code: 'PDF',
    documentType: 'PDF',
    label: 'PDF',
    icon: <Create />,
    url: getDocument('PDF', documents)?.file.url,
    show: true,
  },
  {
    code: 'PURCHASE_ORDER',
    documentType: 'PURCHASE_ORDER',
    label: 'Orden de Compra',
    icon: <Assignment />,
    url: getDocument('PURCHASE_ORDER', documents)?.file.url,
    show: offer.reviewDocumentsManager?.requiresOc,
  },
  {
    code: 'HES',
    documentType: 'HES',
    label: 'Hes',
    icon: <AccountBalance />,
    url: getDocument('HES', documents)?.file.url,
    show: offer.reviewDocumentsManager?.requiresHes,
  },
  {
    code: 'PORTAL_SCREENSHOT',
    documentType: 'PORTAL_SCREENSHOT',
    label: 'Print Portal',
    icon: <TrendingUp />,
    url: getDocument('PORTAL_SCREENSHOT', documents)?.file.url,
    show: offer.reviewDocumentsManager?.requiresPortalScreenshot,
  },
];

const InvoiceDocumentsCell = ({ invoice }) => {
  const inputRef = useRef();
  const [documentToUpload, setDocumentToUpload] = useState({});
  const documentsArray = useMemo(() => (
    buildInvoiceDocuments(invoice).filter((doc) => doc.show)
  ), [invoice]);

  const [uploadInvoiceFile, { loading }] = useMutation(UPLOAD_INVOICE_DOCUMENTS, {
    variables: {
      invoiceId: invoice.id,
    },
  });

  const handleOpenInput = (document) => {
    setDocumentToUpload(document);
    inputRef.current.click();
  };

  const handleChange = useCallback((event) => {
    const file = event.target.files[0];
    uploadInvoiceFile({ variables: {
      inputUploadFiles: [
        {
          documentType: documentToUpload.documentType,
          file,
        },
      ],
    } });
  }, [uploadInvoiceFile, documentToUpload]);

  return (
    <Grid container direction="row">
      {documentsArray.map((doc) => (
        <Grid item key={`${doc.code.toLowerCase()}-item`}>
          <DocumentChip
            document={doc}
            documentToUpload={documentToUpload}
            inputRef={inputRef}
            handleChange={handleChange}
            loading={loading}
            handleOpenInput={handleOpenInput}
            url={doc.url}
          />
        </Grid>
      ))}
    </Grid>
  );
};

InvoiceDocumentsCell.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          code: PropTypes.string,
          shortName: PropTypes.string,
        }).isRequired,
        url: PropTypes.string,
      }),
    ).isRequired,
    offer: PropTypes.shape({
      id: PropTypes.string.isRequired,
      reviewDocumentsManager: PropTypes.shape({
        hesReviewState: PropTypes.string.isRequired,
        ocReviewState: PropTypes.string.isRequired,
        portalScreenshotReviewState: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }).isRequired,
};

export default InvoiceDocumentsCell;
