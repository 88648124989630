import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import WarningRounded from '@mui/icons-material/WarningRounded';
import { useQuery } from '@apollo/client';
import MASTER_ENTITY_TGR from '../../../../graphql/risk_evaluation_model.py/masterEntityTgr';

const CollectionInfoNotFound = ({ masterEntityId }) => {
  const { data: masterEntity, loading } = useQuery(
    MASTER_ENTITY_TGR,
    { variables: { masterEntityId },
      skip: !masterEntityId,
    },
  );
  const tgrJson = useMemo(
    () => masterEntity?.getMasterEntity.company.currentTgr?.tgrInfoDict,
    [masterEntity],
  );
  if (loading || tgrJson?.collection?.length) {
    return <></>;
  }
  return (
    <Typography color="error" justifyContent="center">
      <Stack alignItems="center" direction="row" fontWeight="bold">
        <WarningRounded />
        Cliente: Cobranzas Judiciales, no encontró información
      </Stack>
    </Typography>
  );
};

CollectionInfoNotFound.propTypes = {
  masterEntityId: PropTypes.string.isRequired,
};

export default CollectionInfoNotFound;
