import { gql } from '@apollo/client';
import {
  InvoiceBasicFields,
  InvoiceCollectionFields,
  InvoiceFilesFields,
  InvoicePaymentDiscountFields,
  InvoiceRatesFields,
  InvoiceRatificationFields,
  sharedInvoiceInput,
  sharedInvoiceInputFields,
  TypeConnectionFields,
} from '@fingo/lib/graphql/fragments';
import {
  CompanyDebtsSummary,
  ReceiverDebtsSummary,
} from '@fingo/lib/graphql/customers/fragment';
import { InvoiceRequestingPlatformFields } from '@fingo/lib/graphql/invoices/fragments';

const COLLECTION_INVOICES = gql`
  query LucilaCollectionInvoices(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ... InvoiceBasicFields
          ... InvoiceCollectionFields
          ... InvoiceRatificationFields
          ... InvoiceFilesFields
          ... InvoiceRatesFields
          ... InvoicePaymentDiscountFields
          ... InvoiceRequestingPlatformFields
          company {
            masterEntity {
              ...CompanyDebtsSummary
            }
          }
          receiver {
            ...ReceiverDebtsSummary
          }
        }
      }
      flatInvoices @client {
        ... InvoiceBasicFields
        ... InvoiceCollectionFields
        ... InvoiceFilesFields
        ... InvoiceRatesFields
      }
    }
  }
  ${CompanyDebtsSummary}
  ${ReceiverDebtsSummary}
  ${TypeConnectionFields} 
  ${InvoiceBasicFields} 
  ${InvoiceCollectionFields} 
  ${InvoiceFilesFields} 
  ${InvoiceRatesFields}
  ${InvoiceRatificationFields}
  ${InvoicePaymentDiscountFields}
  ${InvoiceRequestingPlatformFields}
`;

export default COLLECTION_INVOICES;
