import React from 'react';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import FingoTable from '@fingo/lib/components/tables/FingoTable';
import { useGetCountryFromUrl } from '@fingo/lib/hooks';
import Typography from '@mui/material/Typography';
import BuroAuthorizationManagerColumns from './constants/BuroAuthorizationManagerColumns';
import BuroAuthorizationDetails from './BuroAuthorizationFormDetails';
import { GET_MASTER_ENTITIES_BURO_AUTHORIZATION_FORM } from '../../graphql/default/GetMasterEntitiesBuroAuthorizationForm';

const BuroAuthorizationManager = () => {
  const country = useGetCountryFromUrl();
  return (
    <FingoMainView
      type="buro-authorization"
      query={GET_MASTER_ENTITIES_BURO_AUTHORIZATION_FORM}
      queryCustomVariables={{
        countryId: country?.id,
        buroauthorizationformdocument_Isnull: false,
      }}
      slots={{
        header: PaperHeader,
        table: FingoTable,
      }}
      slotProps={{
        header: {
          viewTitle: 'Formularios de Autorización de Buró de Crédito',
          finder: {
            searchPlaceHolder: 'Buscar',
          },
        },
        table: {
          includeHeaders: ['name'],
          columns: BuroAuthorizationManagerColumns,
          checkboxSelection: false,
          isRowSelectable: () => true,
          collapsible: true,
          isRowCollapsible: () => true,
          collapseComponent: ({ row }) => <BuroAuthorizationDetails masterEntity={row} />,
          disableSelectionOnClick: true,
          noRowsMessage: () => <Typography>No hay facturas pendientes de firma</Typography>,
          rowsPerPageOptions: [10, 15],
        },
      }}
    />
  );
};

export default BuroAuthorizationManager;
