import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import useBooleanState from '@fingo/lib/hooks/useBooleanState';
import LoadingButton from '@mui/lab/LoadingButton';
import dayjs from '@fingo/lib/config/dayjs';
import { formatGraphQlDate } from '@fingo/lib/helpers';
import FingoDialog from '@fingo/lib/components/dialogs/FingoDialog';
import CreditBuroHeader from './CreditBuroHeader';
import CreditBuroGenericDetailDataGrid from './CreditBuroGenericDetailDataGrid';

const CreditBuroDetail = ({ query, queryResponse, masterEntityId, disabled }) => {
  const [selectedBuroId, setSelectedBuroId] = useState(null);
  const [selectedTab, setSelectedTab] = useState('externalQueries');
  const [open, setOpen] = useBooleanState();
  const [loadBuroDetail, { data, loading }] = useLazyQuery(query, {
    variables: { masterEntityId },
    fetchPolicy: 'network-only',
    onCompleted: ({ getMasterEntity }) => {
      const burosList = getMasterEntity[queryResponse];
      setSelectedBuroId(burosList[burosList.length - 1].id);
      setOpen(true);
    },
  });
  const selectedBuro = useMemo(
    () => data?.getMasterEntity[queryResponse].find(
      (dicom) => dicom.id === selectedBuroId,
    ),
    [selectedBuroId],
  );
  const availableOptions = data?.getMasterEntity[queryResponse]
    .map((buro) => ({
      timestamp: dayjs(buro.createdAt),
      label: formatGraphQlDate(dayjs(buro.createdAt)),
      value: buro.id,
      key: `buro-${buro.id}`,
    }))
    .sort((a, b) => a.timestamp.diff(b.timestamp));
  return (
    <>
      <LoadingButton
        onClick={loadBuroDetail}
        loading={loading}
        variant="contained"
        disabled={disabled}
        size="small"
      >
        B. de Crédito
      </LoadingButton>
      {open && (
        <FingoDialog
          title={`Buró de Crédito de ${data?.getMasterEntity.name}`}
          open={open}
          handleClose={setOpen}
          maxWidth="lg"
          fullWidth
        >
          <CreditBuroHeader
            queryResponse={queryResponse}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            selectedBuroId={selectedBuroId}
            availableOptions={availableOptions}
            setSelectedBuroId={setSelectedBuroId}
          />
          <CreditBuroGenericDetailDataGrid
            iterable={selectedBuro[selectedTab]}
            columns={selectedTab}
          />
        </FingoDialog>
      )}
    </>
  );
};

CreditBuroDetail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  query: PropTypes.any.isRequired,
  queryResponse: PropTypes.string.isRequired,
  masterEntityId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CreditBuroDetail;
