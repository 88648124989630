import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import useFetchPresignedUrl from '@fingo/lib/hooks/useFetchPresignedUrl';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LoadingIconButton from '@fingo/lib/components/buttons/LoadingIconButton';

const BuroAuthorizationFormDocumentColumn = ({ row }) => {
  const [, loading, fetchGivenId] = useFetchPresignedUrl();
  const openUrl = useCallback((model) => fetchGivenId(model.globalAppId), [fetchGivenId]);
  return (
    <LoadingIconButton
      color="primary"
      disabled={row.status === 'PENDING'}
      onClick={() => openUrl(row)}
      loading={loading}
    >
      <AttachFileIcon />
    </LoadingIconButton>
  );
};

BuroAuthorizationFormDocumentColumn.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    globalAppId: PropTypes.string.isRequired,
  }).isRequired,
};

export default BuroAuthorizationFormDocumentColumn;
