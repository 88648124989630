import React from 'react';
import Chip from '@mui/material/Chip';
import { CompanyProfileCell } from '@fingo/lib/components/cells';
import BuroAuthorizationFormDocumentColumn from './BuroAuthorizationFormDocumentColumn';
import CreditBuroInformDialog from '../dialogs/CreditBuroInformDialog';

const BuroAuthorizationFormDetailsColumns = [
  {
    field: 'name',
    type: 'string',
    headerAlign: 'center',
    headerName: 'RFC Relacionado',
    align: 'center',
    renderCell: ({ row }) => <CompanyProfileCell masterEntity={row.masterEntity} />,
    sortable: true,
    flex: 1,
  },
  {
    field: 'formDocumentType',
    headerName: 'Tipo de Formulario',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 0.6,
    renderCell: ({ row }) => (
      <Chip
        size="small"
        label={row.formDocumentTypeDisplay}
      />
    ),
  },
  {
    field: 'signatureStatus',
    headerName: 'Estado Formulario',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 0.6,
    renderCell: ({ row }) => (
      <Chip
        size="small"
        label={row.statusDisplay}
      />
    ),
  },
  {
    field: 'document',
    headerName: 'Documento',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 0.4,
    renderCell: ({ row }) => <BuroAuthorizationFormDocumentColumn row={row} />,
  },
  {
    field: 'creditBuroPM',
    type: 'date',
    headerName: 'Buró de Crédito PM',
    sortable: false,
    filterable: false,
    align: 'center',
    headerAlign: 'center',
    flex: 0.6,
    renderCell: ({ row }) => <CreditBuroInformDialog row={row} buroType="PM" />,
  },
  {
    field: 'creditBuroPF',
    type: 'date',
    headerName: 'Buró de Crédito PF',
    sortable: false,
    filterable: false,
    align: 'center',
    headerAlign: 'center',
    flex: 0.6,
    renderCell: ({ row }) => <CreditBuroInformDialog row={row} buroType="PF" />,
  },
];

export default BuroAuthorizationFormDetailsColumns;
