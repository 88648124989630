import { gql } from '@apollo/client';

const UPDATE_CREDIT_BUROS = gql`
  mutation updateCreditBuros(
    $entitiesInputs: [UpdateCreditBurosInput]!
  ) {
    updateCreditBuros(
      entitiesInputs: $entitiesInputs
    ) {
      success
    }
  }
`;

export default UPDATE_CREDIT_BUROS;
