import React, { useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import CreditLineEvaluation from '../CreditLineEvaluation';
import BaseRiskResult from './BaseRiskResult';
import RatificationResultVariableContribution from '../RatificationResultVariableContribution';
import RiskDefaultResults from '../RiskDefaultResults';
import RiskPredictionResults from '../RiskPredictionResults';

import { selectedRiskComponentVar } from '../../../../graphql/reactive-variables';

function useRiskModelComponent(invoice) {
  const { riskEvaluationModel, amountWithIva } = invoice;
  const selectedRiskComponent = useReactiveVar(selectedRiskComponentVar);

  return useMemo(() => {
    switch (selectedRiskComponent) {
      case 'risk':
        return (
          <RiskPredictionResults riskEvaluationId={riskEvaluationModel?.id} />
        );
      case 'ratification':
        return (
          <RatificationResultVariableContribution
            riskEvaluationId={riskEvaluationModel?.id}
          />
        );
      case 'default':
        return (
          <RiskDefaultResults riskEvaluationId={riskEvaluationModel?.id} />
        );
      case 'clientLine':
        return (
          <CreditLineEvaluation
            masterEntityId={invoice.company.id}
            masterEntityName={invoice.company.name}
            invoiceAmount={amountWithIva}
          />
        );
      case 'debtorLine':
        return (
          <CreditLineEvaluation
            masterEntityId={invoice.receiver.id}
            masterEntityName={invoice.receiver.name}
            invoiceAmount={amountWithIva}
          />
        );
      case 'tree':
        return (
          <BaseRiskResult
            selectedModel="Tree"
            invoice={invoice}
            riskEvaluation={riskEvaluationModel}
          />
        );
      case 'ras':
        return (
          <BaseRiskResult
            selectedModel="RAS"
            invoice={invoice}
            riskEvaluation={riskEvaluationModel}
          />
        );
      default:
        return null;
    }
  }, [selectedRiskComponent, invoice, riskEvaluationModel, amountWithIva]);
}

export default useRiskModelComponent;
