/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import {
  SLAResolutionFields,
  PreofferEvaluationInfo,
} from '@fingo/lib/graphql/evaluation_requests/fragments';
import {
  CompanyDebtsSummary,
  CompanyDocumentsFields,
  RiskBlacklistFields,
} from '@fingo/lib/graphql/customers/fragment';
import CompanyEvaluationRestrictionMinimalFields from '@fingo/lib/graphql/fragments/company-evaluation-restriction/company-evaluation-restriction-minimal-fields';
import MoneyFields from '@fingo/lib/graphql/fragments/money/money-fields';
import { UserIdentification } from '@fingo/lib/graphql/user/fragments';

export const GET_PREOFFERS_REQUESTS = gql`
  query masterEntities(
    $first: Int
    $offset: Int
    $globalFilter: String
    $orderBy: String
    $inCollection: Int
    $inFactoringEvaluation: Boolean
    $inOrderingEvaluation: Boolean
    $countryId: Int
  ) {
    getMasterEntities(
      first: $first
      offset: $offset
      globalFilter: $globalFilter
      inCollection: $inCollection
      inFactoringEvaluation: $inFactoringEvaluation
      inOrderingEvaluation: $inOrderingEvaluation
      orderBy: $orderBy
      countryId: $countryId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        node {
          id
          rut
          name
          source {
            id
            name
          }
          riskBlacklist {
            ...RiskBlacklistFields
            comments
            createdAt
          }
          displayNationalIdentifier
          currencies {
            id
            isoCode
          }
          ...CompanyDebtsSummary
          company {
            id
            hasLendingAcceptedterms @client
            currentUserAcceptedTerms {
              id
              product
            }
            hasDigitalCertificate
            hasFrameworkContract
            documents {
              ...CompanyDocumentsFields
            }
            executiveAssigned {
              ...UserIdentification
            }
            invoiceInEvaluation {
              id
              folio
              dateIssued
              dateToPay
              receiver {
                id
                rut
                displayNationalIdentifier
                name
              }
              preoffer {
                id
                preofferevaluationrequest {
                  ...PreofferEvaluationInfo
                  assignedEvaluator {
                    ...UserIdentification
                  }
                  slaResolution {
                    ...SLAResolutionFields
                  }
                }
              }
              amountWithIva {
                ...MoneyFields
              }
            }
            companyevaluationrestrictionsSet {
              ...CompanyEvaluationRestrictionMinimalFields
            }
            conservativeTycRequestingPlatform {
              id
              code
            }
          }
        }
      }
    }
  }
  ${SLAResolutionFields}
  ${CompanyDebtsSummary}
  ${MoneyFields}
  ${CompanyDocumentsFields}
  ${UserIdentification}
  ${PreofferEvaluationInfo}
  ${CompanyEvaluationRestrictionMinimalFields}
  ${RiskBlacklistFields}
`;
