import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { useMasterEntityPreColumns } from '@fingo/lib/constants';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import { GET_DEBTORS } from '@fingo/lib/graphql';
import Typography from '@mui/material/Typography';
import React from 'react';

const DebtorAssignations = () => {
  const country = useGetCountryFromUrl();
  return (
    <FingoMainView
      id="customers-manager"
      query={GET_DEBTORS}
      queryCustomVariables={{
        debtor_Isnull: false,
        countryId: country?.id,
      }}
      slots={{
        header: PaperHeader,
        table: FingoDataGrid,
      }}
      slotProps={{
        header: {
          viewTitle: 'Asignación de ejecutivos a deudores',
          finder: {
            searchPlaceHolder: 'Buscar cliente',
          },
        },
        table: {
          columns: useMasterEntityPreColumns(),
          noRowsMessage: () => <Typography>No tienes clientes asociados</Typography>,
          includeHeaders: [
            'name',
            'debtor_RatificationExecutive__firstName',
            'debtor_CollectionExecutive__firstName',
          ],
          rowsPerPageOptions: [15, 25, 50, 100],
        },
      }}
    />
  );
};

export default DebtorAssignations;
