/* eslint-disable max-len */
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { useIsMobile, useSnackBars } from '@fingo/lib/hooks';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import useGetExecutives from '@fingo/lib/hooks/useGetExecutives';
import ExternalLeadsActions from './external-leads/ExternalLeadsActions';
import { GET_EXTERNAL_LEADS, ASSIGN_EXTERNAL_LEADS } from '../../graphql/external-leads';
import { DATA_GRID_COLUMNS, HEADER_TITLE } from '../../constants/external-leads';

const ExternalLeads = () => {
  const [selectedExternalLeadIds, setSelectedExternalLeadIds] = useState([]);
  const isMobile = useIsMobile();
  const { addAlert } = useSnackBars();
  const { executives } = useGetExecutives();

  const [assignExternalLeads, { loading: assignExternalLeadsLoading }] = useMutation(
    ASSIGN_EXTERNAL_LEADS,
    {
      refetchQueries: [GET_EXTERNAL_LEADS],
      onCompleted: ({ assignExternalLeads: { assignedLeads } }) => {
        assignedLeads.forEach((lead) => {
          const { executiveAssigned } = lead.masterEntity.company;
          const executiveFullName = `${executiveAssigned.firstName} ${executiveAssigned.lastName}`;
          addAlert({
            id: lead.id,
            message: `Se asignó el lead ${lead.masterEntity.name} a ${executiveFullName}`,
          });
        });
      },
    },
  );

  const assignExecutive = ({ id: externalLeadId, value: executiveId }) => {
    if (executiveId) {
      assignExternalLeads({
        variables: {
          executiveId,
          externalLeadIds: [externalLeadId],
        },
      });
    }
  };

  const columns = [
    ...DATA_GRID_COLUMNS.map((column) => ({ ...column, width: isMobile ? 60 : 90 })),
    {
      field: 'masterEntity_Company_ExecutiveAssigned_Id',
      headerName: 'Ejecutivo Asignado',
      type: 'singleSelect',
      editable: !assignExternalLeadsLoading,
      sortable: false,
      align: 'center',
      flex: 1,
      valueOptions: () => executives
        ?.filter((executive) => executive.active)
        ?.map((executive) => ({
          label: `${executive.user.firstName} ${executive.user.lastName}`,
          value: executive.user.id,
        }))
      || [],
      valueGetter: ({ row }) => row.masterEntity.company?.executiveAssigned?.id || '',
      valueFormatter: ({ value }) => {
        if (!value) return 'Sin asignación';

        const executive = executives?.find(({ user }) => user.id === value);
        return `${executive?.user?.firstName || 'Sin'} ${executive?.user?.lastName || 'asignación'}`;
      },
    },
  ];

  return (
    <FingoMainView
      id="external-leads"
      query={GET_EXTERNAL_LEADS}
      slots={{
        header: PaperHeader,
        actions: ExternalLeadsActions,
        table: DataGrid,
      }}
      slotProps={{
        header: {
          viewTitle: HEADER_TITLE,
        },
        actions: {
          selectedExternalLeadIds,
          setSelectedExternalLeadIds,
        },
        table: {
          columns,
          checkboxSelection: true,
          disableSelectionOnClick: true,
          onSelectionModelChange: (ids) => { setSelectedExternalLeadIds(ids); },
          selectionModel: selectedExternalLeadIds,
          onCellEditCommit: (params) => assignExecutive(params),
          density: 'compact',
          noRowsMessage: () => <Typography>No hay leads cargados.</Typography>,
          paginationMode: 'server',
          rowsPerPageOptions: [15, 25, 50, 100],
        },
      }}
    />
  );
};

export default ExternalLeads;
