import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useSnackBars } from '@fingo/lib/hooks';
import { SEND_TO_REVISION_CONTRACT, CONTRACT_MASTER_ENTITIES } from '@fingo/lib/graphql';
import FileInput from '@fingo/lib/components/inputs/FileInput';

const SendToRevisionDialog = ({
  open,
  toggleOpen,
  contractRequestId,
}) => {
  const [files, setFiles] = useState([]);
  const { addAlert } = useSnackBars();

  const handleClose = () => {
    toggleOpen(false);
    setFiles([]);
  };

  const [uploadMutation, { loading }] = useMutation(SEND_TO_REVISION_CONTRACT, {
    variables: {
      file: files[0],
      contractRequestId,
    },
    onCompleted: handleClose,
    onError: (err) => addAlert({
      id: 'upload-external-contract-error',
      message: `Error uploading contract: ${err.message}`,
    }),
    refetchQueries: [CONTRACT_MASTER_ENTITIES],
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFiles([file]);
    }
  };

  return (
    <FingoDialog
      title="Enviar contrato a revisión"
      open={open}
      handleClose={handleClose}
      dialogActionButton={(
        <LoadingButton
          key="upload-external-contract-button"
          size="small"
          color="primary"
          variant="contained"
          onClick={uploadMutation}
          loading={loading}
          disabled={!files.length}
        >
          Enviar contrato
        </LoadingButton>
        )}
    >
      <Typography variant="body2" gutterBottom>
        Sube el contrato que deseas enviar a revisión.
      </Typography>
      <FileInput
        variant="filled"
        accept=".pdf,.docx"
        value={files[0]?.name || ''}
        onChange={handleFileChange}
      />
    </FingoDialog>
  );
};

SendToRevisionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  contractRequestId: PropTypes.string.isRequired,
};

export default SendToRevisionDialog;
