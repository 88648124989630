import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import {
  REQUEST_MONEY_TRANSFERS,
  GENERATE_TREASURY_MESSAGE,
  INVOICE_TRANSFER_INFO,
  DELETE_TREASURY_MESSAGE,
  CONFIRMINGS_QUERY,
} from '@fingo/lib/graphql';
import { useSnackBars, useBooleanState } from '@fingo/lib/hooks';
import ORDERING_PURCHASE_ORDERS from '@fingo/lib/graphql/queries/ordering-purchase-orders';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
import { TRANSFERS_MASTER_ENTITIES } from '../../../graphql/queries/transfer';

const AcceptTransfersDialog = ({ selectedDocumentIds, cleanDocuments, modelLabel, data }) => {
  const sumAmountToTransfer = useMemo(() => {
    if (!selectedDocumentIds.length) return 0;
    return data.filter((inv) => selectedDocumentIds.includes(inv.id)).map(
      (inv) => inv.paymentDiscounts.paymentAmountAfterNegativeSurplus.amount,
    ).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }, [selectedDocumentIds]);
  const [openDialog, toggleDialog, , closeDialog] = useBooleanState();
  const { addAlert } = useSnackBars();
  const [generateTreasuryMessage, treasuryMessageData] = useMutation(GENERATE_TREASURY_MESSAGE, {
    variables: {
      documentsIds: selectedDocumentIds,
      modelLabel,
    },
  });

  const message = treasuryMessageData.loading
    ? null
    : treasuryMessageData.data?.generateTreasuryMessage?.createdObject;

  const [deleteTreasuryMessage] = useMutation(DELETE_TREASURY_MESSAGE, {
    variables: {
      id: message?.id,
    },
  });

  const handleClose = useCallback(() => {
    deleteTreasuryMessage();
    closeDialog();
  }, [message]);

  const [requestMoneyTransfers, requestMoneyTransfersResult] = useMutation(
    REQUEST_MONEY_TRANSFERS,
    {
      variables: {
        documentsIds: selectedDocumentIds,
        modelLabel,
        treasuryMessageId: message?.id,
      },
      onCompleted: () => {
        cleanDocuments();
        addAlert({
          severity: 'success',
          message: 'Giros solicitados exitosamente',
        });
        closeDialog();
      },
      onError: (err) => {
        addAlert({
          severity: 'error',
          message: err.message,
        });
      },
      refetchQueries: [
        INVOICE_TRANSFER_INFO, ORDERING_PURCHASE_ORDERS, CONFIRMINGS_QUERY,
        TRANSFERS_MASTER_ENTITIES,
      ],
    },
  );
  useEffect(() => {
    if (openDialog) {
      generateTreasuryMessage();
    }
  }, [openDialog, generateTreasuryMessage]);

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        size="small"
        disableElevation
        onClick={toggleDialog}
        disabled={
          !selectedDocumentIds
          || selectedDocumentIds.length === 0
          || sumAmountToTransfer < 0
        }
        id="toggle-download-portfolio"
      >
        Solicitar giros a tesorería
      </Button>
      <FingoDialog
        open={openDialog}
        handleClose={handleClose}
        maxWidth="md"
        fullWidth
        title="Solicitar giros"
      >
        <Box>
          <Typography variant="h3" sx={{ mt: 3, mb: 2 }}>
            Mensaje a enviar:
          </Typography>
          <Typography sx={{ whiteSpace: 'pre-line' }}>{message && message.message}</Typography>
        </Box>
        <Box justifyContent="right" display="flex">
          <LoadingButton
            color="primary"
            variant="contained"
            size="small"
            disableElevation
            id="toggle-download-portfolio"
            onClick={requestMoneyTransfers}
            loading={requestMoneyTransfersResult.loading}
          >
            Solicitar giros
          </LoadingButton>
        </Box>
      </FingoDialog>
    </>
  );
};

AcceptTransfersDialog.propTypes = {
  selectedDocumentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  cleanDocuments: PropTypes.func.isRequired,
  modelLabel: PropTypes.oneOf(['invoice', 'purchaseorder', 'confirming']).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default AcceptTransfersDialog;
