import React from 'react';
import PropTypes from 'prop-types';
import GET_MASTER_ENTITY_BURO_CREDIT_PF_DETAILS from '../../../graphql/default/GetMasterEntityBuroCreditPFDetails';
import GET_MASTER_ENTITY_BURO_CREDIT_PM_DETAILS from '../../../graphql/default/GetMasterEntityBuroCreditPMDetails';
import CreditBuroDetail from './CreditBuroDetail';

const CreditBuroComponent = (
  { buroType, masterEntityId, disabled },
) => {
  const propsMapper = {
    PF: {
      query: GET_MASTER_ENTITY_BURO_CREDIT_PF_DETAILS,
      queryResponse: 'creditBurosPf',
    },
    PM: {
      query: GET_MASTER_ENTITY_BURO_CREDIT_PM_DETAILS,
      queryResponse: 'creditBurosPm',
    },
  };
  return (
    <CreditBuroDetail
      query={propsMapper[buroType].query}
      queryResponse={propsMapper[buroType].queryResponse}
      masterEntityId={masterEntityId}
      disabled={disabled}
    />
  );
};

CreditBuroComponent.propTypes = {
  buroType: PropTypes.string.isRequired,
  masterEntityId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CreditBuroComponent;
