const CreditBuroGenericDetailColumnMapper = {
  externalQueries: [
    { field: 'date', headerName: 'Fecha Consulta', align: 'center', flex: 1 },
    { field: 'businessName', headerName: 'RFC', align: 'center', flex: 1 },
    { field: 'use', headerName: 'Tipo de Usuario', align: 'center', flex: 1 },
    { field: 'platform', headerName: 'Plataforma', align: 'center', flex: 1 },
  ],
  creditinformburopmshareholderSet: [
    { field: 'personType', headerName: 'Tipo Persona', align: 'center', minWidth: 100 },
    { field: 'rut', headerName: 'RFC', align: 'center', minWidth: 100 },
    { field: 'curp', headerName: 'CURP', align: 'center', minWidth: 100 },
    { field: 'nameShareholder', headerName: 'Nombre Accionista', align: 'center', minWidth: 140 },
    { field: 'secondNameShareholder', headerName: 'Segundo Nombre', align: 'center', minWidth: 140 },
    { field: 'firstLastname', headerName: 'Apellido Paterno', align: 'center', minWidth: 120 },
    { field: 'secondLastname', headerName: 'Apellido Materno', align: 'center', minWidth: 120 },
    { field: 'address1', headerName: 'Direccion 1', align: 'center', minWidth: 150 },
    { field: 'address2', headerName: 'Direccion 2', align: 'center', minWidth: 150 },
    { field: 'neighborhoodOrPopulation', headerName: 'Colonia Poblacion', align: 'center', minWidth: 200 },
  ],
  creditinformburopmfinancialcreditSet: [
    { field: 'rfcCustomer', headerName: 'RFC Cliente', align: 'center', minWidth: 100 },
    { field: 'accountNumber', headerName: 'Numero Cuenta', align: 'center', minWidth: 100 },
    { field: 'userType', headerName: 'Tipo Usuario', align: 'center', minWidth: 100 },
    { field: 'initialBalance', headerName: 'Saldo Inicial', align: 'center', minWidth: 100 },
    { field: 'currency', headerName: 'Moneda', align: 'center', minWidth: 100 },
    { field: 'opening', headerName: 'Apertura', align: 'center', minWidth: 100 },
    { field: 'term', headerName: 'Plazo', align: 'center', minWidth: 100 },
    { field: 'exchangeRate', headerName: 'Tipo Cambio', align: 'center', minWidth: 100 },
    { field: 'observationKey', headerName: 'Clave Observacion', align: 'center', minWidth: 100 },
    { field: 'creditType', headerName: 'Tipo Credito', align: 'center', minWidth: 100 },
    { field: 'currentBalance', headerName: 'Saldo Vigente', align: 'center', minWidth: 100 },
    { field: 'overdueBalance1To29Days', headerName: 'Saldo Vencido De 1 a 29 Dias', align: 'center', minWidth: 200 },
    { field: 'overdueBalance30To59Days', headerName: 'Saldo Vencido De 30 a 59 Dias', align: 'center', minWidth: 200 },
    { field: 'overdueBalance60To89Days', headerName: 'Saldo Vencido De 60 a 89 Dias', align: 'center', minWidth: 200 },
    { field: 'overdueBalance90To119Days', headerName: 'Saldo Vencido De 90 a 119 Dias', align: 'center', minWidth: 200 },
    { field: 'overdueBalance120To179Days', headerName: 'Saldo Vencido De 120 a 179 Dias', align: 'center', minWidth: 200 },
    { field: 'overdueBalance180DaysOrMore', headerName: 'Saldo Vencido De 180 Dias o más', align: 'center', minWidth: 200 },
    { field: 'lastUpdatedPeriod', headerName: 'Ultimo Periodo Actualizado', align: 'center', minWidth: 180 },
    { field: 'closingDate', headerName: 'Fecha Cierre', align: 'center', minWidth: 100 },
    { field: 'closingPayment', headerName: 'Pago Cierre', align: 'center', minWidth: 100 },
    { field: 'writeOff', headerName: 'Quita', align: 'center', minWidth: 100 },
    { field: 'deedInLieu', headerName: 'Dacion', align: 'center', minWidth: 100 },
    { field: 'loss', headerName: 'Quebranto', align: 'center', minWidth: 100 },
    { field: 'paymentHistory', headerName: 'Historico Pagos', align: 'center', minWidth: 150 },
    { field: 'majorDelay', headerName: 'Atraso Mayor', align: 'center', minWidth: 100 },
    { field: 'disputedRecord', headerName: 'Registro Impugnado', align: 'center', minWidth: 150 },
    { field: 'daysHistory', headerName: 'Historia Dias', align: 'center', minWidth: 100 },
    { field: 'numberOfPayments', headerName: 'Numero Pagos', align: 'center', minWidth: 100 },
    { field: 'paymentFrequency', headerName: 'Frecuencia Pagos', align: 'center', minWidth: 140 },
    { field: 'paymentAmount', headerName: 'Monto Pago', align: 'center', minWidth: 100 },
    { field: 'lastPaymentDate', headerName: 'Fecha Ultimo Pago', align: 'center', minWidth: 140 },
    { field: 'restructuringDate', headerName: 'Fecha Reestructura', align: 'center', minWidth: 140 },
    { field: 'firstDefaultDate', headerName: 'Fecha Primer Incumplimiento', align: 'center', minWidth: 200 },
    { field: 'outstandingBalance', headerName: 'Saldo Insoluto', align: 'center', minWidth: 130 },
    { field: 'maximumCreditUsed', headerName: 'Credito Maximo Utilizado', align: 'center', minWidth: 150 },
    { field: 'entryDateToOverduePortfolio', headerName: 'Fecha Ingreso Cartera Vencida', align: 'center', minWidth: 200 },
  ],
  hawkAlertDetails: [
    { field: 'hawkType', headerName: 'Tipo Hawk', align: 'center', flex: 1 },
    { field: 'hawkCode', headerName: 'Codigo Hawk', align: 'center', flex: 1 },
    { field: 'dateReport', headerName: 'Tipo Usuario Reporta', align: 'center', flex: 1 },
    { field: 'claveCode', headerName: 'Codigo Clave', align: 'center', flex: 1 },
    { field: 'institutionType', headerName: 'Tipo Institución', align: 'center', flex: 1 },
    { field: 'message', headerName: 'Descripcion Prevencion Hawk', align: 'center', flex: 1 },
  ],
  creditinformburopmhistoriccreditscoreSet: [
    { field: 'period', headerName: 'Periodo', align: 'center', minWidth: 130 },
    { field: 'currentBalance', headerName: 'Saldo Vigente', align: 'center', minWidth: 130 },
    { field: 'overdueBalance1To29Days', headerName: 'Saldo Vencido 1 A 29 Dias', align: 'center', minWidth: 180 },
    { field: 'overdueBalance30To59Days', headerName: 'Saldo Vencido 30 A 59 Dias', align: 'center', minWidth: 180 },
    { field: 'overdueBalance60To89Days', headerName: 'Saldo Vencido 60 A 89 Dias', align: 'center', minWidth: 180 },
    { field: 'overdueBalance90DaysOrMore', headerName: 'Saldo Vencido A 90 Dias', align: 'center', minWidth: 180 },
    { field: 'portfolioRating', headerName: 'Calificacion Cartera', align: 'center', minWidth: 130 },
    { field: 'maximumOverdueBalance', headerName: 'Maximo Saldo Vencido', align: 'center', minWidth: 160 },
    { field: 'highestNumberOfOverdueDays', headerName: 'Mayor Numero Dias Vencido', align: 'center', minWidth: 200 },
  ],
  creditinformburopmcommercialcreditSet: [
    { field: 'rfcCustomer', headerName: 'RFC Cliente', align: 'center', minWidth: 130 },
    { field: 'userIdentifier', headerName: 'Identificador Usuario', align: 'center', minWidth: 150 },
    { field: 'totalBalance', headerName: 'Saldo Total', align: 'center', minWidth: 130 },
    { field: 'currentBalance', headerName: 'Saldo Vigente', align: 'center', minWidth: 130 },
    { field: 'overdueBalance', headerName: 'Saldo Vencido', align: 'center', minWidth: 130 },
    { field: 'overdueBalance1To29Days', headerName: 'Saldo Vencido De 1 a 29 Dias', align: 'center', minWidth: 200 },
    { field: 'overdueBalance30To59Days', headerName: 'Saldo Vencido De 30 a 59 Dias', align: 'center', minWidth: 200 },
    { field: 'overdueBalance60To89Days', headerName: 'Saldo Vencido De 60 a 89 Dias', align: 'center', minWidth: 200 },
    { field: 'overdueBalance90To119Days', headerName: 'Saldo Vencido De 90 a 119 Dias', align: 'center', minWidth: 200 },
    { field: 'overdueBalance120To179Days', headerName: 'Saldo Vencido De 120 a 179 Dias', align: 'center', minWidth: 200 },
    { field: 'overdueBalance180DaysOrMore', headerName: 'Saldo Vencido De 180 Dias O Mas', align: 'center', minWidth: 220 },
    { field: 'lastUpdatedPeriod', headerName: 'Ultimo Periodo Actualizado', align: 'center', minWidth: 180 },
    { field: 'maximumBalance', headerName: 'Maximo Saldo', align: 'center', minWidth: 130 },
    { field: 'averageBalance', headerName: 'Saldo Promedio', align: 'center', minWidth: 130 },
    { field: 'paymentHistory', headerName: 'Historico Pagos', align: 'center', minWidth: 130 },
    { field: 'disputedRecord', headerName: 'Reg Impugnado', align: 'center', minWidth: 130 },
  ],
  creditinformburopmscoreSet: [
    { field: 'referency', headerName: 'Referencia Consultado', align: 'center', minWidth: 150 },
    { field: 'scoreCode', headerName: 'Codigo Score', align: 'center', minWidth: 130 },
    { field: 'scoreValue', headerName: 'Valor Score', align: 'center', minWidth: 130 },
    { field: 'codeReason1', headerName: 'Codigo Razon1', align: 'center', minWidth: 130 },
    { field: 'codeReason2', headerName: 'Codigo Razon2', align: 'center', minWidth: 130 },
    { field: 'codeReason3', headerName: 'Codigo Razon3', align: 'center', minWidth: 130 },
    { field: 'codeReason4', headerName: 'Codigo Razon4', align: 'center', minWidth: 130 },
    { field: 'scoreError', headerName: 'Error Score', align: 'center', minWidth: 130 },
  ],
  creditInformBuroCreditDetails: [
    { field: 'grantorName', headerName: 'Nombre Otorgante', align: 'center', minWidth: 130 },
    { field: 'grantorPhoneNumber', headerName: 'Numero Telefono Otorgante', align: 'center', minWidth: 180 },
    { field: 'creditInformationAgencyIdentifier', headerName: 'Identificador Sociedad Informacion Crediticia', align: 'center', minWidth: 300 },
    { field: 'responsibilityTypeIndicator', headerName: 'Indicador Tipo Responsabilidad', align: 'center', minWidth: 200 },
    { field: 'accountType', headerName: 'Tipo Cuenta', align: 'center', minWidth: 130 },
    { field: 'contractType', headerName: 'Tipo Contrato', align: 'center', minWidth: 130 },
    { field: 'currencyUnitKey', headerName: 'Clave Unidad Monetaria', align: 'center', minWidth: 160 },
    { field: 'paymentNumber', headerName: 'Numero Pagos', align: 'center', minWidth: 130 },
    { field: 'paymentFrequency', headerName: 'Frecuencia Pagos', align: 'center', minWidth: 130 },
    { field: 'amountToPay', headerName: 'Monto Pagar', align: 'center', minWidth: 130 },
    { field: 'accountOpeningDate', headerName: 'Fecha Apertura Cuenta', align: 'center', minWidth: 150 },
    { field: 'lastPaymentDate', headerName: 'Fecha Ultimo Pago', align: 'center', minWidth: 130 },
    { field: 'lastPurchaseDate', headerName: 'Fecha Ultima Compra', align: 'center', minWidth: 150 },
    { field: 'reportDate', headerName: 'Fecha Reporte', align: 'center', minWidth: 130 },
    { field: 'reportingMode', headerName: 'Modo Reportar', align: 'center', minWidth: 130 },
    { field: 'maximumCredit', headerName: 'Credito Maximo', align: 'center', minWidth: 130 },
    { field: 'currentBalance', headerName: 'Saldo Actual', align: 'center', minWidth: 130 },
    { field: 'creditLimit', headerName: 'Limite Credito', align: 'center', minWidth: 130 },
    { field: 'overdueBalance', headerName: 'Saldo Vencido', align: 'center', minWidth: 130 },
    { field: 'currentPaymentMethod', headerName: 'Forma Pago Actual', align: 'center', minWidth: 130 },
    { field: 'paymentHistory', headerName: 'Historico Pagos', align: 'center', minWidth: 130 },
    { field: 'mostRecentPaymentHistoryDate', headerName: 'Fecha Mas Reciente Historico Pagos', align: 'center', minWidth: 230 },
    { field: 'oldestPaymentHistoryDate', headerName: 'Fecha Mas Antigua Historico Pagos', align: 'center', minWidth: 230 },
    { field: 'highestDelinquencyBalanceAmount', headerName: 'Importe Saldo Morosidad Hist Mas Grave', align: 'center', minWidth: 250 },
    { field: 'mostSevereDelinquencyHistoricalDate', headerName: 'Fecha Historica Morosidad Mas Grave', align: 'center', minWidth: 250 },
    { field: 'mostSevereDelinquencyHistoricalMop', headerName: 'Mop Historico Morosidad Mas Grave', align: 'center', minWidth: 230 },
    { field: 'lastPaymentAmount', headerName: 'Monto Ultimo Pago', align: 'center', minWidth: 130 },
  ],
  creditInformBuroReportSummaryDetails: [
    { field: 'fechaIngresoBD', headerName: 'Fecha Ingreso BD', align: 'center', minWidth: 130 },
    { field: 'numberMop7', headerName: 'Numero MOP 7', align: 'center', minWidth: 130 },
    { field: 'numberMop6', headerName: 'Numero MOP 6', align: 'center', minWidth: 130 },
    { field: 'numberMop5', headerName: 'Numero MOP 5', align: 'center', minWidth: 130 },
    { field: 'numberMop4', headerName: 'Numero MOP 4', align: 'center', minWidth: 130 },
    { field: 'numberMop3', headerName: 'Numero MOP 3', align: 'center', minWidth: 130 },
    { field: 'numberMop2', headerName: 'Numero MOP 2', align: 'center', minWidth: 130 },
    { field: 'numberMop1', headerName: 'Numero MOP 1', align: 'center', minWidth: 130 },
    { field: 'numberMop0', headerName: 'Numero MOP 0', align: 'center', minWidth: 130 },
    { field: 'numberMopur', headerName: 'Numero MOP UR', align: 'center', minWidth: 130 },
    { field: 'numberMop96', headerName: 'Numero MOP 96', align: 'center', minWidth: 130 },
    { field: 'numberMop97', headerName: 'Numero MOP 97', align: 'center', minWidth: 130 },
    { field: 'numberMop99', headerName: 'Numero MOP 99', align: 'center', minWidth: 130 },
    { field: 'numberAccounts', headerName: 'Numero Cuentas', align: 'center', minWidth: 130 },
    { field: 'accountsMortgageFixedPayments', headerName: 'Cuentas Pagos Fijos Hipotecas', align: 'center', minWidth: 200 },
    { field: 'accountsRevolventOpened', headerName: 'Cuentas Revolventes Abiertas', align: 'center', minWidth: 200 },
    { field: 'closedAccounts', headerName: 'Cuentas Cerradas', align: 'center', minWidth: 130 },
    { field: 'currentNegativesAccounts', headerName: 'Cuentas Negativas Actuales', align: 'center', minWidth: 180 },
    { field: 'historicNegativeClaveAccounts', headerName: 'Cuentas Claves Historia Negativa', align: 'center', minWidth: 220 },
    { field: 'disputedAccounts', headerName: 'Cuentas Disputa', align: 'center', minWidth: 130 },
    { field: 'numberRequestsLast6Months', headerName: 'Numero Solicitudes Ultimos 6 Meses', align: 'center', minWidth: 220 },
    { field: 'newAddressReportedLast60Days', headerName: 'Nueva Direccion Reportada Ultimos 60 Dias', align: 'center', minWidth: 270 },
    { field: 'alertMessages', headerName: 'Mensajes Alerta', align: 'center', minWidth: 130 },
    { field: 'customersExistanceDeclarations', headerName: 'Existencia Declaraciones Consumidor', align: 'center', minWidth: 240 },
    { field: 'moneyType', headerName: 'Tipo Moneda', align: 'center', minWidth: 130 },
    { field: 'totalMaxRevolvedCredits', headerName: 'Total Creditos Maximos Revolventes', align: 'center', minWidth: 230 },
    { field: 'totalLimitedRevolvedCredits', headerName: 'Total Limites Credito Revolventes', align: 'center', minWidth: 230 },
    { field: 'totalRevolvedCurrentAccountMoney', headerName: 'Total Saldos Actuales Revolventes', align: 'center', minWidth: 230 },
    { field: 'totalRevolvedExpiredAccountMoney', headerName: 'Total Saldos Vencidos Revolventes', align: 'center', minWidth: 230 },
    { field: 'totalRevolvedPayments', headerName: 'Total Pagos Revolventes', align: 'center', minWidth: 160 },
    { field: 'pctLimitCreditUsedRevolved', headerName: 'Pct Limite Credito Utilizado Revolventes', align: 'center', minWidth: 240 },
    { field: 'totalMaxFixedCreditPayments', headerName: 'Total Creditos Maximos Pagos Fijos', align: 'center', minWidth: 215 },
    { field: 'totalCurrentBalancesFixedPayments', headerName: 'Total Saldos Actuales Pagos Fijos', align: 'center', minWidth: 215 },
    { field: 'totalOverdueBalancesFixedPayments', headerName: 'Total Saldos Vencidos Pagos Fijos', align: 'center', minWidth: 215 },
    { field: 'totalPaymentsFixedPayments', headerName: 'Total Pagos Pagos Fijos', align: 'center', minWidth: 150 },
    { field: 'oldestAccountOpeningDate', headerName: 'Fecha Apertura Cuenta Mas Antigua', align: 'center', minWidth: 220 },
    { field: 'mostRecentAccountOpeningDate', headerName: 'Fecha Apertura Cuenta Mas Reciente', align: 'center', minWidth: 225 },
    { field: 'totalReportRequests', headerName: 'Total Solicitudes Reporte', align: 'center', minWidth: 160 },
    { field: 'mostRecentReportRequestDate', headerName: 'Fecha Solicitud Reporte Mas Reciente', align: 'center', minWidth: 250 },
    { field: 'totalCollectionAccounts', headerName: 'Numero Total Cuentas Despacho Cobranza', align: 'center', minWidth: 265 },
    { field: 'mostRecentCollectionAccountOpeningDate', headerName: 'Fecha Apertura Cuenta Mas Reciente Despacho Cobranza', align: 'center', minWidth: 350 },
    { field: 'totalCollectionRequests', headerName: 'Numero Total Solicitudes Despachos Cobranza', align: 'center', minWidth: 300 },
    { field: 'mostRecentCollectionRequestDate', headerName: 'Fecha Solicitud Mas Reciente Despacho Cobranza', align: 'center', minWidth: 300 },
  ],
  employments: [
    { field: 'companyName', headerName: 'Nombre Empresa', align: 'center', minWidth: 130 },
    { field: 'address1', headerName: 'Direccion 1', align: 'center', minWidth: 130 },
    { field: 'comuna', headerName: 'Colonia Poblacion', align: 'center', minWidth: 130 },
    { field: 'municipio', headerName: 'Delegacion Municipio', align: 'center', minWidth: 160 },
    { field: 'city', headerName: 'Ciudad', align: 'center', minWidth: 130 },
    { field: 'state', headerName: 'Estado', align: 'center', minWidth: 130 },
    { field: 'cp', headerName: 'CP', align: 'center', minWidth: 130 },
    { field: 'role', headerName: 'Cargo', align: 'center', minWidth: 130 },
    { field: 'employmentDate', headerName: 'Fecha Contratacion', align: 'center', minWidth: 130 },
    { field: 'salary', headerName: 'Salario', align: 'center', minWidth: 130 },
    { field: 'baseSalary', headerName: 'Base Salarial', align: 'center', minWidth: 130 },
    { field: 'countryCode', headerName: 'Cod Pais', align: 'center', minWidth: 130 },
    { field: 'employmentReportDate', headerName: 'Fecha Reporto Empleo', align: 'center', minWidth: 160 },
    { field: 'verificationDate', headerName: 'Fecha Verificacion', align: 'center', minWidth: 130 },
    { field: 'verificationMode', headerName: 'Modo Verificacion', align: 'center', minWidth: 130 },
  ],
  addresses: [
    { field: 'city', headerName: 'ciudad', align: 'center', minWidth: 130 },
    { field: 'regionCode', headerName: 'Cod Pais', align: 'center', minWidth: 130 },
    { field: 'additionalInfo', headerName: 'Colonia Poblacion', align: 'center', minWidth: 130 },
    { field: 'number', headerName: 'CP', align: 'center', minWidth: 130 },
    { field: 'comuna', headerName: 'Delegacion Municipio', align: 'center', minWidth: 160 },
    { field: 'street', headerName: 'Direccion 1', align: 'center', minWidth: 130 },
    { field: 'streetAndNumber', headerName: 'Direccion 2', align: 'center', minWidth: 130 },
    { field: 'region', headerName: 'Estado', align: 'center', minWidth: 130 },
    { field: 'addressSource', headerName: 'Extension', align: 'center', minWidth: 130 },
    { field: 'date', headerName: 'Fecha Residencia', align: 'center', minWidth: 130 },
    { field: 'ranking', headerName: 'Indicador Especial Domicilio', align: 'center', minWidth: 180 },
    { field: 'type', headerName: 'Tipo Domicilio', align: 'center', minWidth: 130 },
  ],
};

export default CreditBuroGenericDetailColumnMapper;
