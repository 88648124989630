import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddReaction from '@mui/icons-material/AddReaction';
import { useBooleanState, useSnackBars, useTextFieldInput } from '@fingo/lib/hooks';
import ADD_COLLECTION_ACTION from '@fingo/lib/graphql/mutations/add-collection-action';
import { MasterEntityType, ArrayOfId } from '@fingo/lib/propTypes';
import OperativeStepperDialog from '@fingo/lib/components/dialogs/OperativeStepperDialog';
import { collectionReceiverActionTypes } from '../../features/collection/constants/actions-type';

const stepToTitle = {
  0: 'Selecciona tipo de gestión',
  1: 'Selecciona contacto',
  2: 'Agrega un comentario',
};

const AddActionButton = ({
  managerIds,
  operationType,
  masterEntityTarget,
  setTriggerRefetchDocument,
  disabled,
}) => {
  const ratificationActionTypes = null;
  const [openDialog, toggleDialog, setTrue] = useBooleanState(false);
  const [actionType, setActionType] = useState();
  const [selectedContactsIds, setSelectedContactsIds] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [comment, setComment, resetComment] = useTextFieldInput();
  const { addAlert } = useSnackBars();
  const actionsType = (operationType === 'COLLECTION') ? collectionReceiverActionTypes : ratificationActionTypes;
  const onClose = useCallback(() => {
    setActionType(null);
    setSelectedContactsIds([]);
    resetComment();
    setCurrentStep(0);
    toggleDialog();
  }, []);

  const [addCollectionAction, { loading: loadingAddAction }] = useMutation(ADD_COLLECTION_ACTION, {
    variables: {
      collectionManagerIds: managerIds,
      collectionContactIds: selectedContactsIds,
      actionType,
      comment,
    },
    onCompleted: () => {
      setTriggerRefetchDocument(true);
      addAlert({
        id: 0,
        color: 'primary',
        severity: 'success',
        message: 'Gestión creada exitosamente',
      });
      onClose();
    },
  });

  return (
    <>
      <Button
        id={`${operationType}-action-dialog`}
        color="primary"
        variant="contained"
        startIcon={<AddReaction />}
        sx={{ m: 0.3, height: 25, width: '100%', justifyContent: 'flex-start' }}
        onClick={setTrue}
        disabled={disabled || managerIds.length === 0}
      >
        <Typography noWrap>
          Nueva gestión
        </Typography>
      </Button>
      <OperativeStepperDialog
        open={openDialog}
        onClose={onClose}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        firstStepOptions={actionsType}
        setOptions={setActionType}
        selectedOption={actionType || ''}
        masterEntityTarget={masterEntityTarget}
        operationType={operationType}
        setSelectedContactsIds={setSelectedContactsIds}
        selectedContactsIds={selectedContactsIds}
        lastStepComponent={(
          <TextField
            id="outlined-basic"
            label="Comentario"
            variant="outlined"
            onChange={setComment}
            value={comment}
            sx={{ m: 2 }}
            multiline
            rows={5}
            fullWidth
          />
        )}
        onSubmit={addCollectionAction}
        submitButtonText="Añadir gestión"
        submitButtonId="submit-collection-action"
        loadingSubmit={loadingAddAction}
        stepToTitle={stepToTitle}
      />
    </>
  );
};

AddActionButton.propTypes = {
  managerIds: ArrayOfId,
  operationType: PropTypes.string.isRequired,
  masterEntityTarget: MasterEntityType.isRequired,
  setTriggerRefetchDocument: PropTypes.func,
  disabled: PropTypes.bool,
};

AddActionButton.defaultProps = {
  managerIds: [],
  setTriggerRefetchDocument: () => null,
  disabled: false,
};

export default AddActionButton;
