import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { getFilterByNameFromHistory } from '@fingo/lib/helpers';
import REQUEST_PRE_OFFERS_EVALUATION from '@fingo/lib/graphql/mutations/request-preoffer-evaluation';
import { useBooleanState, useSnackBars } from '@fingo/lib/hooks';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { LoadingButton } from '@mui/lab';
import RuleIcon from '@mui/icons-material/Rule';
import {
  everyInvoiceSameCompany,
  everyInvoiceSameStatus,
} from '../../../helpers';
import ReevaluateDialog from './ReevaluateDialog';
import { selectedInvoiceIdsVar } from '../../../graphql/reactive-variables';

const EvaluationRequestDialog = ({ selectedInvoices }) => {
  const history = useHistory();
  const [open, toggleDialog] = useBooleanState(false);
  const { addAlert } = useSnackBars();
  const count = selectedInvoices.length;
  const companyName = selectedInvoices[0]?.company.masterEntity.name;
  const companyId = selectedInvoices[0]?.company.masterEntity.id;
  const everyInvoiceSameCompanyBoolean = everyInvoiceSameCompany(
    selectedInvoices,
    companyId,
  );
  const everyInvoiceSameStatusBoolean = everyInvoiceSameStatus(selectedInvoices);
  const [evaluationRequest, { loading }] = useMutation(
    REQUEST_PRE_OFFERS_EVALUATION,
    {
      variables: {
        invoiceIds: selectedInvoices.map((inv) => inv.id),
      },
      onCompleted: () => {
        addAlert({
          id: 'lucila-evaluation-request',
          message: 'Factura enviada a evaluación exitosamente',
        });
        selectedInvoiceIdsVar([]);
        toggleDialog();
      },
      refetchQueries: ['myOffers'],
    },
  );
  if (getFilterByNameFromHistory(history, 'hasRejectedPreoffer')) {
    return <ReevaluateDialog selectedInvoices={selectedInvoices} />;
  }
  return (
    <>
      <Tooltip
        title={
          (selectedInvoices.length === 0 && 'Selecciona facturas')
          || (!everyInvoiceSameStatusBoolean
            && 'Las facturas deben estan en el mismo estado de operación')
          || (!everyInvoiceSameCompanyBoolean
            && 'Las facturas deben pertenecer a la misma compañía')
          || (selectedInvoices.length !== 0 && 'Evaluar')
        }
      >
        <div>
          <IconButton
            onClick={toggleDialog}
            disabled={
              !everyInvoiceSameCompanyBoolean || !everyInvoiceSameStatusBoolean
            }
            color="primary"
          >
            <RuleIcon fontSize="small" />
          </IconButton>
        </div>
      </Tooltip>
      <FingoDialog
        open={open}
        handleClose={toggleDialog}
        dialogActionButton={(
          <LoadingButton
            variant="contained"
            size="small"
            onClick={evaluationRequest}
            loading={loading}
          >
            Evaluar
          </LoadingButton>
        )}
      >
        <Typography variant="h4" textAlign="center">
          {`¿Seguro que quieres evaluar ${
            count > 1 ? 'estas' : 'esta'
          } ${count} ${count > 1 ? 'facturas' : 'factura'} de ${companyName}?`}
        </Typography>
      </FingoDialog>
    </>
  );
};

EvaluationRequestDialog.propTypes = {
  selectedInvoices: PropTypes.arrayOf(
    PropTypes.shape({
      company: PropTypes.shape({
        masterEntity: PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.string,
        }),
      }),
    }),
  ),
};

EvaluationRequestDialog.defaultProps = {
  selectedInvoices: [],
};

export default EvaluationRequestDialog;
