import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useQuery } from '@apollo/client';
import ORDERING_PURCHASE_ORDERS from '@fingo/lib/graphql/queries/ordering-purchase-orders';
import { DocumentList } from '@fingo/lib/components/lists';
import { GET_MASTER_ENTITY } from '@fingo/lib/graphql';
import useOrderingLucilaPreColumns from '@fingo/lib/constants/ordering-lucila-pre-columns';
import RoundedWhiteBox from '@fingo/lib/components/boxes/RoundedWhiteBox';
import Box from '@mui/material/Box';
import DebtorOrderingSummary from './DebtorOrderingSummary';

const DebtorOrdering = () => {
  const { debtorId } = useParams();
  const { data } = useQuery(GET_MASTER_ENTITY, {
    variables: { masterEntityId: debtorId },
    skip: !debtorId,
  });
  const debtor = data?.getMasterEntity;
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);

  useEffect(() => {
    if (updateTrigger) {
      setSelectedDocumentIds([]);
      setUpdateTrigger(false);
    }
  }, [updateTrigger]);
  return (
    <Box height="100%" width="100%" p={2} backgroundColor="gray.A200">
      <RoundedWhiteBox height="100%" width="100%">
        <DocumentList
          showGoBack
          trackerId="COLLECTION_ORDERING"
          type="collection-debtor-profile"
          headerTitle={debtor?.name}
          queryDocument={ORDERING_PURCHASE_ORDERS}
          skipQueryDocument={!debtorId}
          allCompaniesOptions
          checkboxSelection
          onSelectionModelChange={(ids) => setSelectedDocumentIds(ids)}
          showFilters
          defaultFilterProps={{
            showValidSiiCredentialsFilter: false,
            showLightningFilter: false,
            showPreoffersFilters: false,
            showStatesFilter: false,
            showDatesFilter: false,
            showRefresh: true,
            showCompanyIssuerFilter: false,
          }}
          customVariables={{
            companyId: debtorId,
            inCollection: true,
            receiver_Rut: null,
            receiverId: null,
            dateIssued_Gte: null,
            dateIssued_Lte: null,
          }}
          includeHeaders={[
            'orderNumber',
            'purchaser_Name',
            'totalAmount',
            'orderingoffer_OrderingFinancedAmount',
            'publicationDate',
            'orderingoffer_InvoiceIssuedDate',
            'agreedDateToPay',
            'executiveAssigned',
            'collectionManagerRelation_Collector',
            'collectionManagerRelation_CollectionPriority_Value',
            'actionsDrawer',
          ]}
          mobileHeaders={['orderNumber', 'purchaser_Name', 'orderingOperationStatus']}
          initialOrderBy="collectionManagerRelation_CollectionPriority_Value"
          getRowId={(row) => row.id}
          updateTrigger={updateTrigger}
          setUpdateTrigger={setUpdateTrigger}
          onCompletedSetLength={(_data) => _data.purchaseOrders.totalCount}
          onCompletedSetRows={(_data) => _data.purchaseOrders.edges.map((edge) => edge.node)}
          customSummaryElement={
            <DebtorOrderingSummary debtor={debtor} selectedDocumentIds={selectedDocumentIds} />
      }
          initialPageSize={100}
          selectionModel={selectedDocumentIds}
          preColumns={useOrderingLucilaPreColumns()}
        />
      </RoundedWhiteBox>
    </Box>
  );
};

export default DebtorOrdering;
