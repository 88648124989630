import React from 'react';
import { FolioInvoiceProfileCell, AmountToCollectCell, CompanyProfileCell, ActionDrawerQueryCell, ActionDrawerCell } from '@fingo/lib/components/cells';
import { Link } from 'react-router-dom';
import OperationalAlertsArray from '@fingo/lib/components/cells/OperationalAlertsArray';
import DateCell from '@fingo/lib/components/cells/DateCell';
import Stack from '@mui/material/Stack';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import CompanyBlacklistComponent from '@fingo/lib/components/cells/CompanyBlacklistComponent';
import { CollectionPriorityCell, AvatarCell, StatusSummaryStepper, DateWithDaysDiffCell } from '@fingo/lib/components/dataGridCells';
import DrawerHeader from '@fingo/lib/components/drawer/DrawerHeader';
import CollectionActions from '@fingo/lib/components/drawer/CollectionActions';
import { formatRut } from 'react-rut-formatter';
import { useIsMobile } from '@fingo/lib/hooks';
import { useWidth } from '@fingo/lib/constants';
import { LUCILA_INVOICE_COLLECTION_ACTIONS } from '../../graphql/queries';

const CollectionInvoicesColumns = () => {
  const isMobile = useIsMobile();
  const {
    dateWidth,
    amountWidth,
  } = useWidth();
  return [
    {
      field: 'folio-invoice-profile',
      headerName: 'Folio',
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      minWidth: 100,
      filterable: false,
      renderCell: ({ row }) => (
        <FolioInvoiceProfileCell
          invoice={row}
          AlertsArray={OperationalAlertsArray}
        />
      ),
    },
    {
      field: 'dateIssued',
      width: dateWidth,
      type: 'date',
      headerName: 'Emisión',
      filterable: false,
      renderCell: ({ row }) => <DateCell date={row.dateIssued} />,
    },
    {
      field: 'receiver_Name',
      headerName: 'Receptor',
      type: 'string',
      minWidth: 150,
      filterable: false,
      flex: 1,
      renderCell: (params) => (
        <Stack alignItems="flex-start" justifyContent="center">
          <Stack direction="row" spacing={1}>
            <MuiLink
              component={Link}
              to={`/app/invoice-debtor/${params.row.receiver.id}`}
              color="primary"
              variant="body2"
              align="left"
              rel="noopener noreferrer"
              underline="hover"
            >
              {params.row.receiver.name}
            </MuiLink>
            <CompanyBlacklistComponent
              masterEntity={params.row.receiver}
              disableDisplay
            />
          </Stack>
          {!isMobile && (
          <Typography variant="subtitle1">
            {params.row.receiver.displayNationalIdentifier}
          </Typography>
          )}
        </Stack>
      ),
    },
    {
      field: 'company_profile',
      type: 'string',
      headerName: 'Emisor',
      renderCell: ({ row }) => (
        <CompanyProfileCell
          masterEntity={row.company.masterEntity}
          showDicom={false}
        />
      ),
      sortable: true,
      minWidth: 100,
      flex: 2,
    },
    {
      field: 'amountWithIvaAndCreditNote',
      headerName: 'Monto',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      width: amountWidth,
      valueGetter: (params) => params.row.amountWithIva.amount,
      valueSetter: (params) => ({
        ...params.row,
        amountWithIva: { ...params.row.amountWithIva, amount: params.value },
      }),
      renderCell: ({ row }) => (
        <AmountToCollectCell row={row} />
      ),
    },
    {
      field: 'dateToPay',
      type: 'date',
      headerName: isMobile ? 'Vencimiento' : 'Fecha de vencimiento',
      resizable: false,
      width: 130,
      renderCell: ({ row }) => (
        <DateWithDaysDiffCell
          date={row.dateToPay}
          status={row.status}
          useColors
        />
      ),
    },
    {
      field: 'agreedDateToPay',
      headerName: 'Fecha de pago',
      sortable: false,
      filterable: false,
      width: 130,
      valueGetter: (params) => params.row.collectionManager?.currentDataForCollection?.dateToPay,
      renderCell: ({ value }) => (
        <DateWithDaysDiffCell date={value} useColors />
      ),
    },
    {
      field: 'collectionManagerRelation_Collector',
      headerName: 'Cobradora',
      filterable: false,
      sortable: false,
      maxWidth: 90,
      flex: 1,
      renderCell: (params) => (
        <AvatarCell user={params.row.collectionManager?.collector} />
      ),
    },
    {
      field: 'siiStatus',
      headerName: 'Estado',
      headerAlign: 'left',
      sortable: false,
      filterable: false,
      width: 120,
      type: 'singleSelect',
      align: 'center',
      renderCell: (params) => (
        <StatusSummaryStepper
          invoice={params.row}
        />
      ),
    },
    {
      field: 'ratificationActions',
      headerName: 'Ratificación',
      type: 'string',
      sortable: false,
      width: 80,
      renderCell: ({ row }) => (
        <ActionDrawerCell
          title={`Gestiones de ratificación factura ${row.folio}`}
          actions={
              row.ratificationmanager ? row.ratificationmanager.actions : []
            }
          headerComponent={DrawerHeader}
          contentComponent={CollectionActions}
          drawerId={row.id}
        />
      ),
    },
    {
      field: 'collectionManagerRelation_CollectionPriority_Value',
      headerName: 'Prioridad',
      sortable: true,
      filterable: true,
      maxWidth: 80,
      flex: 1,
      valueGetter: (params) => params.row.collectionManager.collectionPriority.value,
      renderCell: (params) => (
        <CollectionPriorityCell
          priority={params.row.collectionManager.collectionPriority.value}
        />
      ),
    },
    {
      field: 'actionsDrawer',
      headerName: 'Gestiones',
      type: 'string',
      minWidth: 90,
      filterable: false,
      sortable: false,
      flex: 0.5,
      renderCell: ({ row }) => (
        <ActionDrawerQueryCell
          length={row.collectionManager.amountActions}
          pathToActions={['invoice', 'collectionManager', 'actions']}
          query={LUCILA_INVOICE_COLLECTION_ACTIONS}
          variables={{ invoiceId: row.id }}
          title={row.receiver.name}
          subtitle={formatRut(row.receiver.rut)}
          headerComponent={DrawerHeader}
          showLastActionDate
          contentComponent={CollectionActions}
          lastDate={row.collectionManager.lastActionCreatedAt}
        />
      ),
    },
  ];
};

export default CollectionInvoicesColumns;
