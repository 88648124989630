import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const CreditBuroHeader = (
  {
    selectedTab,
    queryResponse,
    setSelectedTab,
    selectedBuroId,
    availableOptions,
    setSelectedBuroId,
  },
) => {
  const MenuItems = [
    { value: 'externalQueries', label: 'Historial Consultas', creditBurosPf: true, creditBurosPm: true },
    { value: 'creditinformburopmshareholderSet', label: 'Accionistas', creditBurosPf: false, creditBurosPm: true },
    { value: 'creditinformburopmfinancialcreditSet', label: 'Crédito Financiero', creditBurosPf: false, creditBurosPm: true },
    { value: 'hawkAlertDetails', label: 'Alertas Hawk', creditBurosPf: true, creditBurosPm: true },
    { value: 'creditinformburopmhistoriccreditscoreSet', label: 'Credit Score Histórico', creditBurosPf: false, creditBurosPm: true },
    { value: 'creditinformburopmcommercialcreditSet', label: 'Crédito Comercial', creditBurosPf: false, creditBurosPm: true },
    { value: 'creditinformburopmscoreSet', label: 'Score', creditBurosPf: false, creditBurosPm: true },
    { value: 'creditInformBuroCreditDetails', label: 'Cuentas', creditBurosPf: true, creditBurosPm: false },
    { value: 'creditInformBuroReportSummaryDetails', label: 'Resumen Reporte', creditBurosPf: true, creditBurosPm: false },
    { value: 'employments', label: 'Empleos', creditBurosPf: true, creditBurosPm: false },
    { value: 'addresses', label: 'Direcciones', creditBurosPf: true, creditBurosPm: false },
  ];

  return (
    <Stack direction="row" spacing={6} justifyContent="center">
      <Select
        centered
        value={selectedTab}
        onChange={(e) => setSelectedTab(e.target.value)}
      >
        {MenuItems.filter((item) => item[queryResponse]).map((item) => (
          <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
        ))}
      </Select>
      <Stack spacing={0.5} alignItems="center">
        <Typography><b>Fecha de reporte</b></Typography>
        <Select
          id="report-selector"
          value={selectedBuroId}
          onChange={(e) => setSelectedBuroId(e.target.value)}
          variant="outlined"
          placeholder="Seleccione un reporte"
        >
          {availableOptions.map((option) => (
            <MenuItem
              key={option.key}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </Stack>
  );
};

CreditBuroHeader.propTypes = {
  queryResponse: PropTypes.string.isRequired,
  selectedTab: PropTypes.string.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  availableOptions: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string,
    id: PropTypes.string,
    key: PropTypes.string,
  })),
  selectedBuroId: PropTypes.string,
  setSelectedBuroId: PropTypes.func.isRequired,
};

CreditBuroHeader.defaultProps = {
  selectedBuroId: '',
  availableOptions: [],
};

export default CreditBuroHeader;
