import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

const UpdateBuroDialogColumns = (updateBuroCheck) => [
  {
    field: 'rut',
    headerName: 'RFC',
    align: 'center',
    flex: 1,
  },
  {
    field: 'updatePF',
    headerName: 'Actualizar Buró de Crédito PF',
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    flex: 1,
    valueGetter: ({ row }) => row.updatePF,
    renderCell: ({ row }) => (
      <FormGroup>
        <Checkbox
          checked={row.updatePF}
          disabled={!row.isNaturalPersonRut}
          name="updatePF"
          onChange={() => updateBuroCheck(row.id, 'updatePF')}
        />

      </FormGroup>

    ),
  },
  {
    field: 'updatePM',
    headerName: 'Actualizar Buró de Crédito PM',
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    flex: 1,
    valueGetter: ({ row }) => row.updatePM,
    renderCell: ({ row }) => (
      <FormGroup>
        <Checkbox
          checked={row.updatePM}
          disabled={!row.isNaturalPersonRut}
          name="updatePM"
          onChange={() => updateBuroCheck(row.id, 'updatePM')}
        />
      </FormGroup>

    ),
  },
];

export default UpdateBuroDialogColumns;
