import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { PaperHeader } from '@fingo/lib/components/headers';
import { useInvoicePreColumns } from '@fingo/lib/constants';
import { INVOICE_FOLIOS } from '@fingo/lib/graphql';
import FingoTable from '@fingo/lib/components/tables/FingoTable';
import MY_SALES_PURCHASES from '@fingo/lib/graphql/queries/my-sales-purchases';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';

const SelectableInvoicesTable = ({ label, companyId, value, onChange }) => {
  const includingColumns = [
    'folio-invoice-profile',
    'dteType_Code',
    'dateIssued',
    'operationDateToPay',
    'amountWithIva',
  ];

  const columns = useInvoicePreColumns(includingColumns);

  const [fetchFolios, { data }] = useLazyQuery(INVOICE_FOLIOS);
  const folios = useMemo(() => {
    if (data) {
      return data.invoices.edges.map((edge) => ({
        id: edge.node.id,
        folio: edge.node.folio,
      }));
    }
    return [];
  }, [data]);

  const handleSelectionChange = (newSelection) => {
    fetchFolios({ variables: { id_In: newSelection } });
    onChange(newSelection);
  };

  const handleDeselectFolio = (id) => {
    const newSelection = value.filter((selectedId) => selectedId !== id);
    fetchFolios({ variables: { id_In: newSelection } });
    onChange(newSelection);
  };

  return (
    <Stack sx={{ height: 'auto' }}>
      <FingoMainView
        id="selectable-invoices-view"
        query={MY_SALES_PURCHASES}
        queryCustomVariables={{
          companyId,
          hasPendingCollection: true,
        }}
        hideReloadButton
        slots={{
          header: PaperHeader,
          table: FingoTable,
        }}
        slotProps={{
          header: {
            viewTitle: label,
            sx: {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              '& .MuiTypography-root': {
                fontSize: '1rem',
                flex: 1,
              },
            },
            finder: {
              searchPlaceHolder: 'Buscar folios...',
            },
          },
          table: {
            checkboxSelection: true,
            isRowSelectable: () => true,
            columns,
            initialOrderBy: '-dateIssued',
            noRowsMessage: () => <Typography>Sin facturas disponibles</Typography>,
            onSelectionModelChange: handleSelectionChange,
            includeHeaders: includingColumns,
            selectionModel: value,
            rowsPerPageOptions: [5, 10, 20],
            initialPageSize: 5,
          },
        }}
      />
      <Box mb={2}>
        <Grid container spacing={1} mt={1}>
          {folios.map(({ id, folio }) => (
            <Grid item key={id}>
              <Chip
                label={folio}
                onDelete={() => handleDeselectFolio(id)}
                deleteIcon={<CloseIcon />}
                color="primary"
                sx={{
                  fontSize: '0.75rem',
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Stack>
  );
};

SelectableInvoicesTable.propTypes = {
  label: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};

SelectableInvoicesTable.defaultProps = {
  value: [],
};

export default SelectableInvoicesTable;
