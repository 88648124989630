import React from 'react';
import { CompanyProfileCell } from '@fingo/lib/components/cells';

const BuroAuthorizationManagerColumns = [
  {
    field: 'name',
    type: 'string',
    headerName: 'Empresa',
    renderCell: ({ row }) => <CompanyProfileCell masterEntity={row} />,
    sortable: true,
    flex: 2,
  },
];

export default BuroAuthorizationManagerColumns;
