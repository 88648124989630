import { gql } from '@apollo/client';

const GET_MASTER_ENTITY_BURO_CREDIT_PF_DETAILS = gql`
  query masterEntity($masterEntityId: Int!) {
    getMasterEntity(masterEntityId: $masterEntityId) {
      id
      name
      creditBurosPf {
        id
        createdAt
        firstName
        middleName
        lastPaternalName
        lastMaternalName
        dateOfBirth
        prefix
        nationality
        numberDependencies
        operatorReferentialNumber
        countryCode
        buroIdentifier
        otorgantPassword
        controlNumberQuery
        externalQueries {
          id
          createdAt
          date
          businessName
          use
          platform
        }
        creditInformBuroCreditDetails {
          id
          createdAt
          updateDate
          grantorName
          grantorPhoneNumber
          creditInformationAgencyIdentifier
          responsibilityTypeIndicator
          accountType
          contractType
          currencyUnitKey
          paymentNumber
          paymentFrequency
          amountToPay
          accountOpeningDate
          lastPaymentDate
          lastPurchaseDate
          reportDate
          reportingMode
          maximumCredit
          currentBalance
          creditLimit
          overdueBalance
          currentPaymentMethod
          paymentHistory
          mostRecentPaymentHistoryDate
          oldestPaymentHistoryDate
          highestDelinquencyBalanceAmount
          mostSevereDelinquencyHistoricalDate
          mostSevereDelinquencyHistoricalMop
          lastPaymentAmount
        }
        hawkAlertDetails {
          id
          createdAt
          hawkType
          hawkCode
          dateReport
          claveCode
          institutionType
          message
        }
        creditInformBuroReportSummaryDetails {
          id
          createdAt
          fechaIngresoBD
          numberMop7
          numberMop6
          numberMop5
          numberMop4
          numberMop3
          numberMop2
          numberMop1
          numberMop0
          numberMopur
          numberMop96
          numberMop97
          numberMop99
          numberAccounts
          accountsMortgageFixedPayments
          accountsRevolventOpened
          closedAccounts
          currentNegativesAccounts
          historicNegativeClaveAccounts
          disputedAccounts
          numberRequestsLast6Months
          newAddressReportedLast60Days
          alertMessages
          customersExistanceDeclarations
          moneyType
          totalMaxRevolvedCredits
          totalLimitedRevolvedCredits
          totalRevolvedCurrentAccountMoney
          totalRevolvedExpiredAccountMoney
          totalRevolvedPayments
          pctLimitCreditUsedRevolved
          totalMaxFixedCreditPayments
          totalCurrentBalancesFixedPayments
          totalOverdueBalancesFixedPayments
          totalPaymentsFixedPayments
          oldestAccountOpeningDate
          mostRecentAccountOpeningDate
          totalReportRequests
          mostRecentReportRequestDate
          totalCollectionAccounts
          mostRecentCollectionAccountOpeningDate
          totalCollectionRequests
          mostRecentCollectionRequestDate
        }
        employments {
          id
          createdAt
          companyName
          address1
          comuna
          municipio
          city
          state
          cp
          role
          employmentDate
          salary
          baseSalary
          countryCode
          employmentReportDate
          verificationDate
          verificationMode
        }
        addresses {
          id
          createdAt
          date
          type
          additionalInfo
          city
          regionCode
          comuna
          lasAddressVerificationDate
          number
          postalCode
          region
          addressSource
          street
          streetAndNumber
          rank
          ranking
          source
        }
      }
    }
  }
`;

export default GET_MASTER_ENTITY_BURO_CREDIT_PF_DETAILS;
