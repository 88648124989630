import React from 'react';
import { useMutation } from '@apollo/client';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { CHECK_CONTRACT_STATUS } from '@fingo/lib/graphql';
import { useSnackBars } from '@fingo/lib/hooks';
import RefreshIcon from '@mui/icons-material/Refresh';

const CheckContractStatusButton = ({ contractRequest }) => {
  const { addAlert } = useSnackBars();
  const contractRequestId = contractRequest.id;
  const [checkContractStatus, { loading }] = useMutation(
    CHECK_CONTRACT_STATUS,
  );
  const disabled = contractRequest.status !== 'CREATED';

  const handleClick = () => {
    checkContractStatus({
      variables: {
        contractRequestId,
      },
      onCompleted: () => {
        addAlert({
          id: 'check-contract-status',
          message: 'Estado del contrato actualizado correctamente',
        });
      },
    });
  };
  return (
    <Tooltip title="Comprobar estado del contrato">
      <Typography component="span">
        <IconButton
          onClick={handleClick}
          loading={loading}
          disabled={disabled}
          size="small"
          sx={{
            color: 'primary.main',
            '&:disabled': {
              color: 'grey',
            },
          }}
        >
          <RefreshIcon />
        </IconButton>
      </Typography>
    </Tooltip>
  );
};

CheckContractStatusButton.propTypes = {
  contractRequest: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
};

export default CheckContractStatusButton;
