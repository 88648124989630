import React, { useMemo, useState } from 'react';
import { DocumentList } from '@fingo/lib/components/lists';
import {
  useInvoicePreColumns,
  useInvoiceLucilaCustomColumns,
  FINGO_MASTER_ENTITY_ID,
} from '@fingo/lib/constants';
import { formatYearMonthDay } from '@fingo/lib/helpers';
import dayjs from '@fingo/lib/config/dayjs';
import IconButton from '@mui/material/IconButton';
import MoreVert from '@mui/icons-material/MoreVert';
import { DownloadExcelFromMenu, MenuForActions } from './headers-actions';
import COLLECTION_INVOICES from '../../../collection/graphql/queries/collection-invoices';

const CollectionManager = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  return (
    <DocumentList
      trackerId="COLLECTION_MANAGER"
      type="collection-invoices-list"
      headerTitle="Lista de facturas"
      queryDocument={COLLECTION_INVOICES}
      countryFilter
      initialOrderBy="dateToPay"
      includeHeaders={[
        'folio-invoice-profile',
        'dateIssued',
        'company_profile',
        'receiver_sortable',
        'amountWithIvaAndDebt',
        'surplusDebt',
        'dateToPay',
        'agreedDateToPay',
        'offerMonthlyRate',
        'offerDefaultRate',
        'offerRetentionRate',
        'collectionManagerRelation_Collector',
        'siiStatus',
        'collectionManagerRelation_CollectionPriority_Value',
        'actionsDrawer',
      ]}
      mobileHeaders={['folio', 'receiver_Name', 'cessionStatus']}
      showFilters
      defaultFilterProps={{
        showStatesFilter: false,
        showDatesFilter: false,
        showCompanyIssuerFilter: true,
        defaultFilterDays: 150,
        showExecutiveFilter: true,
        showHuntingFarmingFilter: true,
      }}
      allCompaniesOptions
      getRowId={(row) => row.id}
      customVariables={{
        receiver_Rut: null,
        receiverId: null,
        inCollection: FINGO_MASTER_ENTITY_ID,
        dateIssued_Gte: useMemo(
          () => formatYearMonthDay(dayjs().subtract(150, 'days')),
          [],
        ),
        filterByRequestingPlatformOrAssigned: 'FINGO',
        status: ['Debt', 'Transfered'],
      }}
      flexEndButtons={(rowCount, commonVars) => (
        <>
          <div>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <MoreVert />
            </IconButton>
            <MenuForActions
              actions={[DownloadExcelFromMenu]}
              rowCount={rowCount}
              commonVars={commonVars}
              open={open}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
            />
          </div>
        </>
      )}
      preColumns={useInvoicePreColumns(useInvoiceLucilaCustomColumns(false))}
    />
  );
};

export default CollectionManager;
