import React from 'react';
import PropTypes from 'prop-types';
import ContactCard from '@fingo/lib/components/cards/ContactCard';
import { ArrayOfId, MasterEntityType } from '@fingo/lib/propTypes';
import Grid from '@mui/material/Grid';
import { CONTACT_TYPES } from '@fingo/lib/constants';
import DebtorFactoringSummaryStatistics from './DebtorFactoringSummaryStatistics';
import DebtorActions from './DebtorActions';
import COLLECTION_INVOICES from '../../graphql/queries/collection-invoices';

const DebtorFactoringSummary = ({ debtor, selectedDocumentIds,
  showActions, currency }) => (
    <Grid
      container
      id="debtor-summary"
      direction="row"
      bgcolor="gray.A100"
      justifyContent="center"
      alignItems="stretch"
      padding={1}
      columns={10}
      width="100%"
      borderRadius={2}
    >
      <Grid
        item
        xs={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <ContactCard
          contactType={CONTACT_TYPES.COLLECTION}
          masterEntity={debtor}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <DebtorFactoringSummaryStatistics debtor={debtor} currency={currency} sx={{ flex: 1 }} />
      </Grid>
      {showActions && (
      <Grid
        item
        xs={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <DebtorActions
          selectedDocumentIds={selectedDocumentIds}
          debtor={debtor}
          sx={{ flex: 1 }}
          query={COLLECTION_INVOICES}
        />
      </Grid>
      )}
    </Grid>
);

DebtorFactoringSummary.propTypes = {
  selectedDocumentIds: ArrayOfId,
  debtor: MasterEntityType,
  showActions: PropTypes.bool,
  currency: PropTypes.string,
};

DebtorFactoringSummary.defaultProps = {
  selectedDocumentIds: [],
  debtor: null,
  showActions: false,
  currency: '',
};

export default DebtorFactoringSummary;
