import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useBooleanState } from '@fingo/lib/hooks';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import Tooltip from '@mui/material/Tooltip';
import ApproveContractDialog from './ApproveContractDialog';

const ApproveContractButton = ({ contractRequest }) => {
  const [open, toggleDialog] = useBooleanState(false);

  const currentContractUploadRequest = contractRequest?.currentContractUploadRequest;

  const disabled = !currentContractUploadRequest || currentContractUploadRequest.approvedRequest;

  return (
    <>
      <Tooltip title="Aprobar contrato">
        <Typography
          sx={{
            zIndex: 9999,
          }}
          component="span"
        >
          <IconButton
            onClick={toggleDialog}
            size="small"
            disabled={disabled}
            sx={{
              color: 'primary.main',
              '&:disabled': {
                color: 'grey',
              },
            }}
          >
            <RateReviewOutlinedIcon />
          </IconButton>
        </Typography>
      </Tooltip>
      <ApproveContractDialog
        open={open}
        toggleOpen={toggleDialog}
        contractUploadRequestId={currentContractUploadRequest?.id}
      />
    </>
  );
};

ApproveContractButton.propTypes = {
  contractRequest: PropTypes.shape({
    id: PropTypes.string.isRequired,
    currentContractUploadRequest: PropTypes.shape({
      id: PropTypes.string.isRequired,
      approvedRequest: PropTypes.bool.isRequired,
    }),
  }).isRequired,
};

export default ApproveContractButton;
