import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import DateTimeCell from '@fingo/lib/components/cells/DateTimeCell';
import dayjs from '@fingo/lib/config/dayjs';
import CreditBuroComponent from './CreditBuroComponent';

const CreditBuroInformDialog = ({ row, buroType }) => {
  const { masterEntity } = row;
  const currentBuro = (
    buroType === 'PM' ? masterEntity.currentCreditInformBuroPm : masterEntity.currentCreditInformBuroPf
  );
  return (
    <>
      <Stack direction="row" spacing={1}>
        <CreditBuroComponent
          buroType={buroType}
          masterEntityId={masterEntity.id}
          disabled={!currentBuro}
        />
        {currentBuro && (
        <Stack direction="column" alignSelf="center">
          <DateTimeCell fullDate={dayjs(currentBuro.createdAt)} />
        </Stack>
        )}
      </Stack>
    </>
  );
};

CreditBuroInformDialog.propTypes = {
  buroType: PropTypes.string.isRequired,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    masterEntity: PropTypes.shape({
      id: PropTypes.string.isRequired,
      isNaturalPersonRut: PropTypes.bool.isRequired,
      currentCreditInformBuroPf: PropTypes.shape({
        id: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
      }),
      currentCreditInformBuroPm: PropTypes.shape({
        id: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
      }),
    }),

  }).isRequired,
};

export default CreditBuroInformDialog;
