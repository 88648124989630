import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { DataGrid } from '@mui/x-data-grid';
import CreditBuroGenericDetailColumnMapper from '../constants/CreditBuroGenericDetailColumnMapper';

const CreditBuroGenericDetailDataGrid = ({ iterable, columns }) => (
  <Stack
    sx={{ minHeight: 250,
      width: '100%',
      overflowX: 'auto',
    }}
  >
    <DataGrid
      rows={iterable ?? []}
      columns={CreditBuroGenericDetailColumnMapper[columns]}
    />
  </Stack>
);

CreditBuroGenericDetailDataGrid.propTypes = {
  columns: PropTypes.string.isRequired,
  iterable: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    createdAt: PropTypes.string,
    date: PropTypes.string,
    businessName: PropTypes.string,
    use: PropTypes.string,
    platform: PropTypes.string,
  })).isRequired,
};

export default CreditBuroGenericDetailDataGrid;
