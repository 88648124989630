import LazyImage from '@fingo/lib/components/images/LazyImage';
import { FingoNavbar } from '@fingo/lib/components/layout';
import { LoggedOutNavItems, LogoNavItem } from '@fingo/lib/components/layout/navbar';
import CountryNavItem from '@fingo/lib/components/layout/navbar/CountryNavItem';
import { useGetUser, useIsLogged } from '@fingo/lib/hooks';
import React, { useMemo } from 'react';
import LoggedInDesktopNavItems from './LoggedInDesktopNavItems';

const DesktopNavbar = () => {
  const isLogged = useIsLogged();
  const user = useGetUser();

  const navbar = useMemo(() => (
    <FingoNavbar>
      <LogoNavItem
        image={(
          <LazyImage
            imagePath="images/logos/lucila-logo.png"
            width={120}
            height={26}
            alt="fingo logo"
          />
        )}
        isLogged={isLogged}
      />
      {isLogged && user && <LoggedInDesktopNavItems />}
      {!isLogged && <LoggedOutNavItems title="Iniciar Sesión" />}
      <CountryNavItem />
    </FingoNavbar>

  ), [isLogged, user]);
  return navbar;
};

export default DesktopNavbar;
