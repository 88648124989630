import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useSnackBars } from '@fingo/lib/hooks';
import { UPLOAD_EXTERNAL_CONTRACT, GET_COMPANY_CONTRACT_GENERATION } from '@fingo/lib/graphql';
import FileInput from '@fingo/lib/components/inputs/FileInput';

const UploadExternalContractDialog = ({
  open,
  toggleOpen,
  companyId,
}) => {
  const [files, setFiles] = useState([]);
  const [contractName, setContractName] = useState('');
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');
  const { addAlert } = useSnackBars();
  const [company, setCompany] = useState({});

  const { loading: loadingCompany } = useQuery(GET_COMPANY_CONTRACT_GENERATION, {
    variables: { companyId },
    notifyOnNetworkStatusChange: true,
    skip: !open,
    onCompleted: (data) => {
      setCompany(data.company);
      setContractName(`Contrato - ${data.company.name}`);
    },
    onError: (err) => addAlert({
      id: 'company-query-error',
      message: `Error fetching company data: ${err.message}`,
    }),
  });

  const contractRequest = company?.pendingFrameworkContractRequest;

  const handleClose = () => {
    toggleOpen(false);
    setFiles([]);
    setContractName('');
    setEmail('');
    setUserName('');
  };

  const [uploadMutation, { loading }] = useMutation(UPLOAD_EXTERNAL_CONTRACT, {
    variables: {
      file: files[0],
      contractName,
      legalRepresentativeEmail: email,
      legalRepresentativeName: userName,
      contractRequestId: contractRequest?.id,
    },
    onCompleted: handleClose,
    onError: (err) => addAlert({
      id: 'upload-external-contract-error',
      message: `Error uploading contract: ${err.message}`,
    }),
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFiles([file]);
    }
  };

  return (
    <FingoDialog
      title="Subir a Firma Ya"
      open={open}
      handleClose={handleClose}
      dialogActionButton={(
        <LoadingButton
          key="upload-external-contract-button"
          size="small"
          color="primary"
          variant="contained"
          onClick={uploadMutation}
          loading={loading || loadingCompany}
          disabled={!files.length || !contractName || !email || !userName}
        >
          Subir contrato
        </LoadingButton>
        )}
    >
      <TextField
        fullWidth
        label="Nombre del Contrato"
        value={contractName}
        onChange={(e) => setContractName(e.target.value)}
        margin="normal"
        disabled
      />
      <TextField
        fullWidth
        label="Correo Electrónico"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        margin="normal"
        type="email"
      />
      <TextField
        fullWidth
        label="Nombre del Usuario"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
        margin="normal"
      />
      <FileInput
        variant="filled"
        accept=".pdf"
        value={files[0]?.name || ''}
        onChange={handleFileChange}
      />
    </FingoDialog>
  );
};

UploadExternalContractDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
};

export default UploadExternalContractDialog;
