import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { MasterEntityType } from '@fingo/lib/propTypes';
import { FINGO_MASTER_ENTITY_ID } from '@fingo/lib/constants';
import DebtorSummaryStatisticsGrid from '@fingo/lib/features/debtor/DebtorSummaryStatisticsGrid';
import INVOICE_DEBTOR_SUMMARY from '@fingo/lib/graphql/queries/invoice-debtor-summary';

const DebtorFactoringSummaryStatistics = ({ debtor, currency }) => {
  const { data, loading } = useQuery(INVOICE_DEBTOR_SUMMARY, {
    variables: {
      ownerId: FINGO_MASTER_ENTITY_ID,
      debtorId: debtor?.id,
      currency,
    },
    skip: !debtor,
  });

  const {
    currentDebtAmount,
    badDebtAmount,
    averagePaymentTerm,
    collectedInvoices,
    lateCollectedInvoices,
  } = Object.values(data || [])?.[0] || {};
  const debtorType = debtor?.debtor?.classification.code;

  return (
    <DebtorSummaryStatisticsGrid
      debtorType={debtorType}
      averagePaymentTerm={averagePaymentTerm}
      currentDebtAmount={currentDebtAmount}
      loading={loading}
      lateCollectedInvoices={lateCollectedInvoices}
      badDebtAmount={badDebtAmount}
      collectedInvoices={collectedInvoices}
    />
  );
};

DebtorFactoringSummaryStatistics.propTypes = {
  debtor: MasterEntityType,
  currency: PropTypes.string,
};

DebtorFactoringSummaryStatistics.defaultProps = {
  debtor: null,
  currency: '',
};

export default DebtorFactoringSummaryStatistics;
