import React from 'react';
import { FingoPaper } from '@fingo/lib/components/layout';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import { useGetUser } from '@fingo/lib/hooks';
import { buroAuthorizationTabs } from '../routes';

const BuroAuthorization = () => (
  <FingoPaper tabs={buroAuthorizationTabs(
    useGetUser(),
    useGetCountryFromUrl(),
  )}
  />
);

export default BuroAuthorization;
