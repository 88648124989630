import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { GenerateContractButton } from '@fingo/lib/components/contractGeneration';
import ApproveContractButton from './ApproveContractButton';
import CheckContractStatusButton from './CheckContractStatusButton';
import SendToRevisionButton from './SendToRevisionButton';
import SolveContractRequestButton from './SolveContractRequestButton';

const ContractActionsColumn = ({ company }) => (
  <Stack direction="row">
    <CheckContractStatusButton contractRequest={company.pendingFrameworkContractRequest} />
    <GenerateContractButton
      companyId={company.id}
      contractRequest={company.pendingFrameworkContractRequest}
    />
    <SendToRevisionButton contractRequest={company.pendingFrameworkContractRequest} />
    <ApproveContractButton contractRequest={company.pendingFrameworkContractRequest} />
    <SolveContractRequestButton contractRequest={company.pendingFrameworkContractRequest} />
  </Stack>
);

ContractActionsColumn.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.string.isRequired,
    pendingFrameworkContractRequest: PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ContractActionsColumn;
