import { gql } from '@apollo/client';

const GET_MASTER_ENTITY_BURO_CREDIT_PM_DETAILS = gql`
  query masterEntity($masterEntityId: Int!) {
    getMasterEntity(masterEntityId: $masterEntityId) {
      id
      name
      creditBurosPm {
        id
        createdAt
        customerType
        rfcCustomer
        curp
        firstName
        middleName
        lastName
        secondLastName
        addressLine1
        addressLine2
        neighborhoodOrPopulation
        municipalityOrDelegation
        city
        state
        postalCode
        country
        phone
        extension
        fax
        nationality
        portfolioRating
        economicActivity1
        economicActivity2
        economicActivity3
        preventionKey
        financialEntityQueryLast3Months
        financialEntityQueryLast12Months
        financialEntityQueryLast24Months
        financialEntityQueryMoreThan24Months
        commercialCompanyQueryLast3Months
        commercialCompanyQueryLast12Months
        commercialCompanyQueryLast24Months
        commercialCompanyQueryMoreThan24Months
        additionalInformationIndicator
        relatedPersonPreventionKey
        disputedPreventionKey
        disputedRelatedPersonPreventionKey
        externalQueries {
          id
          createdAt
          date
          businessName
          use
          platform
        }
        creditinformburopmshareholderSet {
          id
          createdAt
          personType
          rut
          curp
          nameShareholder
          secondNameShareholder
          firstLastname
          secondLastname
          address1
          address2
          neighborhoodOrPopulation
        }
        creditinformburopmfinancialcreditSet {
          id
          createdAt
          rfcCustomer
          accountNumber
          userType
          initialBalance
          currency
          opening
          term
          exchangeRate
          observationKey
          creditType
          currentBalance
          overdueBalance1To29Days
          overdueBalance30To59Days
          overdueBalance60To89Days
          overdueBalance90To119Days
          overdueBalance120To179Days
          overdueBalance180DaysOrMore
          lastUpdatedPeriod
          closingDate
          closingPayment
          writeOff
          deedInLieu
          loss
          paymentHistory
          majorDelay
          disputedRecord
          daysHistory
          numberOfPayments
          paymentFrequency
          paymentAmount
          lastPaymentDate
          restructuringDate
          firstDefaultDate
          outstandingBalance
          maximumCreditUsed
          entryDateToOverduePortfolio
        }
        hawkAlertDetails {
          id
          createdAt
          hawkType
          hawkCode
          dateReport
          claveCode
          institutionType
          message
        }
        creditinformburopmhistoriccreditscoreSet {
          id
          createdAt
          period
          currentBalance
          overdueBalance1To29Days
          overdueBalance30To59Days
          overdueBalance60To89Days
          overdueBalance90DaysOrMore
          portfolioRating
          maximumOverdueBalance
          highestNumberOfOverdueDays
        }
        creditinformburopmcommercialcreditSet {
          id
          createdAt
          rfcCustomer
          userIdentifier
          totalBalance
          currentBalance
          overdueBalance
          overdueBalance1To29Days
          overdueBalance30To59Days
          overdueBalance60To89Days
          overdueBalance90To119Days
          overdueBalance120To179Days
          overdueBalance180DaysOrMore
          lastUpdatedPeriod
          maximumBalance
          averageBalance
          paymentHistory
          disputedRecord
        }
        creditinformburopmscoreSet {
          id
          createdAt
          referency
          scoreCode
          scoreValue
          codeReason1
          codeReason2
          codeReason3
          codeReason4
          scoreError
        }
      }
    }
  }
`;

export default GET_MASTER_ENTITY_BURO_CREDIT_PM_DETAILS;
