/* eslint-disable max-len */
import React from 'react';
import {
  CompanyCell,
  MoneyCell,
  SiiStatusTextCell,
  TimeInStageCell,
  InvoiceLoansRelatedCell,
  AmountWithIvaCell,
  CompanyProfileCell,
  ComercialOperationAlertArray,
  FolioWithProfileAndAlerts,
  FolioInvoiceProfileCell,
  TextCell,
  RestrictionDetailCell,
} from '@fingo/lib/components/cells';
import TooltipTypography from '@fingo/lib/components/typography/TooltipTypography';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { formatMoney, RestrictionsColorMapper } from '@fingo/lib/helpers';
import Edit from '@mui/icons-material/Edit';
import { TotalAmountCell } from '@fingo/lib/components/dataGridCells';
import TransferAmountSummary from '../components/transfers/TransferAmountSummary';
import TransferInvoiceRequirements from '../components/transfers/TransferInvoiceRequirements';
import AcceptInvoiceLoanTransfersDialog from '../components/transfers/dialogs/AcceptInvoiceLoanTransfersDialog';
import TransferCompanyRequirements from '../components/transfers/TransferCompanyRequirements';
import { RiskRestrictionDocumentCell } from '../components/transfers/cells';

export const FACTORING_HEADERS = [
  'timeInTransferStage',
  'folio-profile',
  'company_profile',
  'receiver_sortable',
  'anticipateAmount',
  'transferAmount',
  'amountWithIva',
  'requirements',
  'siiStatus',
];
export const FACTORING_MOBILE_HEADERS = [
  'folio-profile',
  'anticipateAmount',
  'transferAmount',
  'amountWithIva',
  'requirements',
  'siiStatus',
];

export const PURCHASE_ORDERS_COLUMNS = [
  'invoiceMoneyTransfer_createdAt',
  'orderNumber',
  'company_MasterEntity_Name',
  'purchaser_Name',
  'totalAmount',
  'orderingoffer_OrderingPaymentAmount',
  'publicationDate',
];

export const CONFIRMING_COLUMNS = [
  'simpleFolio',
  'company_Name',
  'confirmingReceiver_Name',
  'amountWithIva',
  'advanceAmount',
  'offerDateToPay',
  'requirements',
  'timeInTransferStage',
];

export const LOANDS_HEADERS = [
  'id',
  'fund',
  'amount',
  'requestTransfer',
];

export const FACTORING_TRANSFER_MASTER_ENTITY_HEADERS = [
  'name',
  'timeInTransferStage',
  'company_ExecutiveAssigned__firstName',
  'invoicesCount',
  'invoicesTotalTransferAmount',
  'invoicesTotalAmount',
  'companyRequirements',
];

export const FACTORING_TRANSFER_MASTER_ENTITY_COLUMNS = [
  {
    field: 'companyRequirements',
    headerName: 'Requerimientos',
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => <TransferCompanyRequirements masterEntity={row} />,
  },
  {
    field: 'invoicesCount',
    type: 'string',
    headerName: 'Cantidad Facturas',
    sortable: true,
    filterable: false,
    renderCell: ({ row }) => (
      <TextCell
        text={`${(row.company.invoicesInPendingTransfer.length)}`}
      />
    ),
  },
  {
    field: 'invoicesTotalAmount',
    headerName: 'Monto Total',
    sortable: true,
    filterable: false,
    renderCell: ({ row }) => {
      const moneyFields = row.company.invoicesInPendingTransfer?.map((invoice) => ({
        amount: invoice.amountWithIva.amount,
        currency: invoice.amountWithIva.currency,
      })) || [];
      return (
        <TotalAmountCell moneyFields={moneyFields} />
      );
    },
  },
  {
    field: 'invoicesTotalTransferAmount',
    headerName: 'Monto Giro Total',
    sortable: true,
    filterable: false,
    renderCell: ({ row }) => {
      const moneyFields = row.company.invoicesInPendingTransfer?.map((invoice) => ({
        amount: invoice.paymentDiscounts.paymentAmountAfterNegativeSurplus.amount,
        currency: invoice.paymentDiscounts.paymentAmountAfterNegativeSurplus.currency,
      })) || [];
      return (
        <TotalAmountCell moneyFields={moneyFields} />
      );
    },
  },
  {
    field: 'timeInTransferStage',
    width: 145,
    type: 'dateTime',
    headerName: 'Tiempo en esta etapa',
    filterable: false,
    renderCell: ({ row }) => (
      <TimeInStageCell time={row.company.invoicesInPendingTransfer?.at(0)
        .invoiceMoneyTransfer.createdAt}
      />
    ),
  },
];

export const FACTORING_COLUMNS = [
  {
    field: 'folio',
    headerName: 'Folio',
    type: 'string',
    align: 'center',
    maxWidth: 70,
    renderCell: ({ row }) => (
      <FolioWithProfileAndAlerts invoice={row} AlertsArray={ComercialOperationAlertArray} />
    ),
    order: 2,
  },
  {
    field: 'folio-profile',
    headerName: 'Folio',
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    minWidth: 90,
    filterable: false,
    flex: 1,
    renderCell: ({ row }) => (
      <FolioInvoiceProfileCell invoice={row} AlertsArray={ComercialOperationAlertArray} />
    ),
  },
  {
    field: 'siiStatus',
    headerName: 'Estado',
    sortable: false,
    filterable: false,
    width: 120,
    renderCell: ({ row }) => <SiiStatusTextCell invoice={row} />,
  },
  {
    field: 'requirements',
    headerName: 'Cesión',
    sortable: false,
    filterable: false,
    width: 65,
    renderCell: ({ row }) => <TransferInvoiceRequirements invoice={row} selectedRequirements={['cession_certificate']} />,
  },
  {
    field: 'timeInTransferStage',
    width: 145,
    type: 'dateTime',
    headerName: 'Tiempo en esta etapa',
    filterable: false,
    renderCell: ({ row }) => <TimeInStageCell time={row.invoiceMoneyTransfer.createdAt} />,
  },
  {
    field: 'company_profile',
    type: 'string',
    headerName: 'Emisor',
    renderCell: ({ row }) => (
      <CompanyProfileCell
        masterEntity={row.company.masterEntity}
        showDicom
      />
    ),
    sortable: true,
    flex: 2,
  },
  {
    field: 'receiver_sortable',
    type: 'string',
    headerName: 'Receptor',
    renderCell: ({ row }) => (
      <CompanyProfileCell
        masterEntity={row.receiver}
        showDicom
        type="receiver"
      />
    ),
    sortable: true,
    minWidth: 150,
    flex: 2,
  },
  {
    field: 'transferAmount',
    headerName: 'Monto Giro',
    width: 120,
    renderCell: ({ row }) => (
      <TooltipTypography variant="subtitle1" title={<TransferAmountSummary invoice={row} />}>
        <MoneyCell amount={row.paymentDiscounts.paymentAmountAfterNegativeSurplus} />
      </TooltipTypography>
    ),
  },
  {
    field: 'amountWithIva',
    headerName: 'Monto',
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    width: 90,
    renderCell: ({ row }) => (
      <AmountWithIvaCell
        row={row}
      />
    ),
  },
];

export const LOANS_COLUMNS = [
  {
    field: 'id',
    headerName: 'ID',
    width: 70,
    renderCell: ({ row }) => <InvoiceLoansRelatedCell row={row} />,
    align: 'center',
  },
  {
    field: 'amount',
    type: 'string',
    headerName: 'Monto a Girar',
    renderCell: ({ row }) => (
      <Stack direction="column">
        <Typography variant="body2" width="inherit" noWrap>
          ${formatMoney(row.totalAmount)}
        </Typography>
        <Typography variant="caption" width="inherit" noWrap>
          Intereses: ${formatMoney(row.interestAssociated)}
        </Typography>
      </Stack>
    ),
    align: 'center',
    sortable: true,
    flex: 2,
  },
  {
    field: 'fund',
    type: 'string',
    headerName: 'Fondo a Transferir',
    renderCell: ({ row }) => <CompanyCell company={row.relatedRosterFund} showBlacklist />,
    align: 'center',
    sortable: true,
    flex: 2,
  },
  {
    field: 'requestTransfer',
    type: 'string',
    headerName: 'Giro',
    renderCell: ({ row }) => <AcceptInvoiceLoanTransfersDialog id={row.id} />,
    align: 'center',
    sortable: true,
    flex: 2,
  },
];

export const RISK_RESTRICTION_COLUMNS = [
  {
    field: 'restriction',
    headerName: 'Restricción',
    align: 'left',
    width: 170,
    editable: false,
    valueGetter: ({ row }) => (row.contractType ? `${row.restrictionDisplay} - ${row.contractTypeLabel}` : row.restrictionDisplay),
  },
  {
    field: 'status',
    headerName: 'Estado',
    headerAlign: 'center',
    align: 'center',
    width: 100,
    editable: false,
    sortable: false,
    renderCell: ({ row }) => <Chip size="small" label={row.statusLabel} color={RestrictionsColorMapper(row.status)} />,
  },
  {
    field: 'type',
    headerName: 'Tipo',
    headerAlign: 'center',
    align: 'center',
    width: 100,
    editable: false,
    sortable: false,
    renderCell: ({ row }) => (
      <Chip
        size="small"
        label={{ Risk: 'Riesgo', Operations: 'Operaciones' }[row.restrictionType]}
        color={{ Risk: 'warning', Operations: 'default' }[row.restrictionType]}
      />
    ),
  },
  {
    field: 'detail',
    headerName: 'Detalle',
    align: 'left',
    width: 170,
    renderCell: ({ row }) => (
      <Box sx={{ wordWrap: 'break-word', whiteSpace: 'normal', padding: '8px 0' }}>
        <RestrictionDetailCell row={row} />
      </Box>

    ),
    editable: false,
  },
  {
    field: 'description',
    headerName: 'Observación',
    flex: 1,
    editable: false,
  },
  {
    field: 'resolutionExplanation',
    headerName: 'Resolución',
    flex: 1,
    editable: true,
    renderCell: ({ value, row }) => {
      if (row.status === 'CREATED') {
        if (!value) {
          return (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}>
              <Edit />
            </Box>
          );
        }
      }
      return <span>{value}</span>;
    },
  },
];

export const CONFIRMING_CUSTOM_COLUMNS = [
  {
    field: 'company_Name',
    type: 'string',
    headerName: 'Empresa',
    renderCell: ({ row }) => <CompanyCell company={row?.company.masterEntity} showBlacklist />,
    sortable: true,
    flex: 2,
  },
  {
    field: 'confirmingReceiver_Name',
    headerName: 'Receptor',
    type: 'string',
    flex: 2,
    renderCell: ({ row }) => <CompanyCell company={row.receiver.masterEntity} showBlacklist showDebtsSummary />,
  },
  {
    field: 'requirements',
    headerName: 'Requerimientos',
    sortable: false,
    filterable: false,
    width: 180,
    renderCell: ({ row }) => <TransferInvoiceRequirements invoice={row} />,
  },
  {
    field: 'advanceAmount',
    headerName: 'Monto Giro',
    width: 120,
    renderCell: ({ row }) => <MoneyCell amount={row.advanceAmount} />,
  },
  {
    field: 'timeInTransferStage',
    width: 145,
    type: 'dateTime',
    headerName: 'Tiempo en esta etapa',
    filterable: false,
    renderCell: ({ row }) => <TimeInStageCell time={row.invoiceMoneyTransfer.createdAt} />,
  },
  {
    field: 'confirmingCreatedAtStage',
    width: 145,
    type: 'dateTime',
    headerName: 'Tiempo en esta etapa',
    filterable: false,
    renderCell: ({ row }) => <TimeInStageCell time={row.createdAt} />,
  },
];

export const HISTORY_RESTRICTION_COLUMNS = [
  {
    field: 'restriction',
    headerName: 'Restricción',
    align: 'left',
    width: 150,
    editable: false,
    sortable: false,
    valueGetter: ({ row }) => row.restrictionDisplay,
  },
  {
    field: 'status',
    headerName: 'Estado',
    headerAlign: 'center',
    align: 'center',
    width: 70,
    editable: false,
    sortable: false,
    renderCell: ({ row }) => <Chip size="small" label={row.statusLabel} color={RestrictionsColorMapper(row.status)} />,
  },
  {
    field: 'type',
    headerName: 'Tipo',
    headerAlign: 'center',
    align: 'center',
    width: 70,
    editable: false,
    sortable: false,
    renderCell: ({ row }) => (
      <Chip
        size="small"
        label={{ Risk: 'Riesgo', Operations: 'Operaciones' }[row.restrictionType]}
        color={{ Risk: 'warning', Operations: 'default' }[row.restrictionType]}
      />
    ),
  },
  {
    field: 'resolutionExplanation',
    headerName: 'Resolución',
    headerAlign: 'center',
    width: 150,
    editable: false,
    sortable: false,
    renderCell: ({ row }) => <span>{row.resolutionExplanation}</span>,
  },
  {
    field: 'archivo',
    headerName: 'Archivo',
    headerAlign: 'center',
    align: 'center',
    width: 130,
    sortable: false,
    renderHeader: () => (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="body1" fontWeight="500">
          Archivo
        </Typography>
      </Stack>
    ),
    renderCell: ({ row }) => (
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <RiskRestrictionDocumentCell
          row={row}
          company={row.company}
        />
      </Box>
    ),
  },
];
