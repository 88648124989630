/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_MASTER_ENTITIES_BURO_AUTHORIZATION_FORM = gql`
  query masterEntities(
    $first: Int
    $offset: Int
    $globalFilter: String
    $orderBy: String
    $countryId: Int
    $buroauthorizationformdocument_Isnull: Boolean
  ) {
    getMasterEntities(
      first: $first
      offset: $offset
      globalFilter: $globalFilter
      orderBy: $orderBy
      countryId: $countryId
      buroauthorizationformdocument_Isnull: $buroauthorizationformdocument_Isnull
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        node {
          id
          rut
          name
          isNaturalPersonRut
          displayNationalIdentifier
          source {
            id
            name
          }
          currentCreditInformBuroPf {
            id
            createdAt
          }
          currentCreditInformBuroPm {
            id
            createdAt
          }
          buroauthorizationformdocumentSet {
            id
            globalAppId
            status
            formDocumentTypeDisplay
            statusDisplay
          }
          company {
            id
            legalRepresentative {
              id
              relatedPerson {
                id
                rut
                name
                isNaturalPersonRut
                displayNationalIdentifier
                source {
                  id
                  name
                }
                currentCreditInformBuroPf {
                  id
                  createdAt
                }
                currentCreditInformBuroPm {
                  id
                  createdAt
                }
                buroauthorizationformdocumentSet {
                  id
                  globalAppId
                  status
                  formDocumentTypeDisplay
                  statusDisplay
                }
              }
            }
          }
        }
      }
    }
  }
`;
