import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Upload from '@mui/icons-material/Upload';
import PropTypes from 'prop-types';
import { useBooleanState } from '@fingo/lib/hooks';
import SendToRevisionDialog from './SendToRevisionDialog';

const SendToRevisionButton = ({ contractRequest }) => {
  const [open, toggleDialog] = useBooleanState(false);
  const contractRequestId = contractRequest.id;
  const isDisabled = contractRequest?.currentContractUploadRequest?.approvedRequest;
  return (
    <>
      <Tooltip title="Solicitar revisión del contrato">
        <Typography
          sx={{
            zIndex: 9999,
          }}
          component="span"
        >
          <IconButton
            onClick={toggleDialog}
            size="small"
            disabled={isDisabled}
            sx={{
              color: 'primary.main',
              '&:disabled': {
                color: 'grey',
              },
            }}
          >
            <Upload />
          </IconButton>
        </Typography>
      </Tooltip>
      <SendToRevisionDialog
        open={open}
        toggleOpen={toggleDialog}
        contractRequestId={contractRequestId}
      />
    </>
  );
};

SendToRevisionButton.propTypes = {
  contractRequest: PropTypes.shape({
    id: PropTypes.string.isRequired,
    currentContractUploadRequest: PropTypes.shape({
      approvedRequest: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
};

export default SendToRevisionButton;
