import { gql } from '@apollo/client';

const MASTER_ENTITY_TGR = gql`
  query masterEntityTgr($masterEntityId: Int!) {
    getMasterEntity(masterEntityId: $masterEntityId) {
      id
      company {
        id
        rut
        currentTgr {
          id
          tgrInfoDict
        }
      }
    }
  }
`;

export default MASTER_ENTITY_TGR;
