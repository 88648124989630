import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Assignment from '@mui/icons-material/Assignment';
import useFetchPresignedUrl from '@fingo/lib/hooks/useFetchPresignedUrl';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const CurrentContract = ({ currentContractUploadRequest }) => {
  const [,, fetchGivenId] = useFetchPresignedUrl();
  const theme = useTheme();

  const { status, documentToUpload } = currentContractUploadRequest || {};
  const colorMap = {
    APPROVED: theme.palette.success.main,
    PENDING: theme.palette.info.main,
    REJECTED: theme.palette.error.main,
  };

  const statusColor = colorMap[status] || theme.palette.text.secondary;

  const tooltipTitle = {
    APPROVED: 'Ver contrato',
    PENDING: 'El contrato está pendiente de revisión',
    REJECTED: 'El contrato ha sido rechazado',
  };

  const toolTip = tooltipTitle[status] || 'Contrato no subido';
  const openUrl = useCallback((model) => fetchGivenId(model.globalAppId), [fetchGivenId]);

  return (
    <Tooltip title={toolTip}>
      <Typography component="span">
        <IconButton
          disabled={!documentToUpload}
          sx={{
            color: statusColor,
            '&.Mui-disabled': { color: theme.palette.action.disabled },
          }}
          onClick={() => openUrl(documentToUpload)}
        >
          <Assignment />
        </IconButton>
      </Typography>
    </Tooltip>
  );
};

CurrentContract.propTypes = {
  currentContractUploadRequest: PropTypes.shape({
    documentToUpload: PropTypes.shape({
      documentType: PropTypes.string,
      exists: PropTypes.bool.isRequired,
      lastDate: PropTypes.string,
      globalAppId: PropTypes.string.isRequired,
    }),
    status: PropTypes.oneOf(['Approved', 'Pending', 'Rejected']).isRequired,
  }).isRequired,
};

export default CurrentContract;
