import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import Stack from '@mui/material/Stack';
import BuroAuthorizationFormDetailsColumns from './constants/BuroAuthorizationFormDetailsColumns';
import UpdateBuroDialog from './UpdateBuroDialog';
import groupRelatedFormMasterEntities from './hooks/groupRelatedFormMasterEntities';

const BuroAuthorizationDetails = ({ masterEntity }) => {
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const rows = groupRelatedFormMasterEntities(masterEntity);
  const addDocuments = (ids) => {
    setSelectedDocuments(ids.map((id) => {
      const buroDoc = rows.find((row) => row.id === id);
      return {
        id: buroDoc.masterEntity.id,
        rut: buroDoc.masterEntity.rut,
        isNaturalPersonRut: buroDoc.masterEntity.isNaturalPersonRut,
        updatePF: false,
        updatePM: !buroDoc.masterEntity.isNaturalPersonRut,
      };
    }));
  };
  return (
    <>
      <Stack alignItems="flex-end" align="flex">
        <UpdateBuroDialog entities={selectedDocuments} setEntities={setSelectedDocuments} />
      </Stack>
      <FingoDataGrid
        checkboxSelection
        isRowSelectable={({ row }) => row.status === 'DOCUMENTSIGNED'}
        onSelectionModelChange={addDocuments}
        rows={rows}
        columns={BuroAuthorizationFormDetailsColumns}
        serverFilters={false}
        hideFooter
      />
    </>
  );
};
BuroAuthorizationDetails.propTypes = {
  masterEntity: PropTypes.shape({
    id: PropTypes.string,
    buroauthorizationformdocumentSet: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ),
    company: PropTypes.shape({
      id: PropTypes.string,
      legalRepresentative: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes,
        }),
      ),
    }),
  }).isRequired,
};

export default BuroAuthorizationDetails;
