import React from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import useSnackBars from '@fingo/lib/hooks/useSnackBars';
import useBooleanState from '@fingo/lib/hooks/useBooleanState';
import FingoDialog from '@fingo/lib/components/dialogs/FingoDialog';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import UpdateBuroDialogColumns from './constants/UpdateBuroDialogColumns';
import UPDATE_CREDIT_BUROS from '../../graphql/bureaus/UpdateCreditBuros';

const UpdateBuroDialog = ({ entities, setEntities }) => {
  const { addAlert } = useSnackBars();
  const [openAccept, toggleOpenAccept] = useBooleanState();
  const updateBuroCheck = (id, update) => {
    setEntities(entities.map((obj) => (obj.id === id
      ? { ...obj, [update]: !obj[update] }
      : obj)));
  };
  const [updateBureaus, { loading }] = useMutation(
    UPDATE_CREDIT_BUROS,
    {
      variables: { entitiesInputs: entities },
      onCompleted: () => {
        addAlert({
          message: 'Burós de Créditos solicitados. Por favor recargar la pagina en unos minutos',
          color: 'success',
          show: true,
          timeout: 10000,
        });
        toggleOpenAccept();
      },
      onError: () => {
        addAlert({
          message: 'Error al actualizar los burós de créditos. Si este error persiste por favor comunícate con Soporte',
          color: 'error',
          show: true,
          timeout: 10000,
        });
      },
    },
  );
  return (
    <>
      <Button
        sx={{ width: '15%' }}
        variant="contained"
        size="small"
        onClick={toggleOpenAccept}
        disabled={!entities.length}
      >
        Actualizar Buró de Crédito
      </Button>
      {openAccept && (
      <FingoDialog
        id="update-credit-buro-dialog"
        key="update-credit-buro-dialog"
        title="Actualizar Buró de Crédito"
        subtitle={`¿Quieres actualizar el Buró de Crédito de esta/s ${entities.length} persona/s?`}
        open={openAccept}
        handleClose={toggleOpenAccept}
        maxWidth="lg"
        fullWidth
        dialogActionButton={(
          <LoadingButton onClick={updateBureaus} loading={loading}>
            Continuar
          </LoadingButton>
        )}
      >
        <Box sx={{ height: 200 }}>
          <DataGrid
            rows={entities}
            columns={UpdateBuroDialogColumns(updateBuroCheck)}
            density="compact"
          />
        </Box>
      </FingoDialog>
      )}
    </>
  );
};

UpdateBuroDialog.propTypes = {
  setEntities: PropTypes.func.isRequired,
  entities: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    rut: PropTypes.string.isRequired,
    updatePF: PropTypes.bool.isRequired,
    updatePM: PropTypes.bool.isRequired,
  })).isRequired,
};

export default UpdateBuroDialog;
