import { FingoPaper } from '@fingo/lib/components/layout';
import { hasShowSubRoutes, userHasRoutePermission } from '@fingo/lib/helpers/routes';

import CreditScore from '@mui/icons-material/CreditScore';
import GavelOutlined from '@mui/icons-material/GavelOutlined';
import ManageHistory from '@mui/icons-material/ManageHistory';
import ReportProblemRounded from '@mui/icons-material/ReportProblemRounded';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import SendOutlined from '@mui/icons-material/SendOutlined';
import Description from '@mui/icons-material/Description';
import ViewList from '@mui/icons-material/ViewList';

import React from 'react';
import {
  BlacklistManager,
  BureausManager,
  CreditLineEvaluationRequests,
  CreditLineManager,
  Ratification,
  RiskEvaluations,
  RiskPreEvaluations,
} from '../components';
import { Blacklist } from '../components/blacklist';
import { CreditLines } from '../components/credit-line-manager';
import BuroAuthorization from '../components/BuroAuthorization';
import PendingSignature from '../components/pending-signature';
import PendingSignatureManager from '../components/pending-signature-manager';
import BuroAuthorizationManager from '../components/buro-authorization-manager';
import {
  NonPriorityRatificationDebtors,
  PriorityRatificationDebtors,
  RatificationDocumentsReview,
  RatificationWithPriority,
  RatificationWithoutPriority,
} from '../components/ratification/index';
import { RiskBureaus } from '../components/risk-bureaus';
import { RiskEvaluationView, RiskPreEvaluationView, RiskEvaluationViewOrdering } from '../components/risk-evaluations';

export const riskPreEvaluationsTabs = (user) => [
  {
    id: 'preevaluations',
    label: 'Pre Evaluaciones',
    path: 'preevaluations',
    component: <RiskPreEvaluationView />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'backoffice_risk.view_riskevaluationmodel'),
  },
];

export const riskEvaluationsTabs = (user) => [
  {
    id: 'factoring',
    label: 'Factoring',
    path: 'factoring',
    component: <RiskEvaluationView />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'backoffice_risk.view_riskevaluationmodel'),
  },
  {
    id: 'ordering',
    label: 'Ordering',
    path: 'ordering',
    component: <RiskEvaluationViewOrdering />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'backoffice_risk.view_riskevaluationmodel'),
  },
];

export const creditLineManagerTabs = (user) => [
  {
    id: 'credit-line-manager',
    label: 'Maestro de líneas',
    path: 'credit-line-manager',
    component: <CreditLines />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'customers.view_creditlineinfo'),
  },
];

export const ratificationTabs = (user) => [
  {
    id: 'debtors-with-priority',
    label: 'Deudores Prioritarios',
    path: 'debtors-with-priority',
    component: <PriorityRatificationDebtors />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'ratification.add_ratificationmanager'),
  },
  {
    id: 'with-priority',
    label: 'Prioritario',
    path: 'with-priority',
    component: <RatificationWithPriority />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'ratification.view_ratificationmanager'),
  },
  {
    id: 'debtors-without-priority',
    label: 'Deudores no prioritario',
    path: 'debtors-without-priority',
    component: <NonPriorityRatificationDebtors />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'ratification.add_ratificationmanager'),
  },
  {
    id: 'without-priority',
    label: 'No prioritario',
    path: 'without-priority',
    component: <RatificationWithoutPriority />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'ratification.add_ratificationmanager'),
  },
  {
    id: 'documents_review',
    label: 'Revisión de OC y HES',
    path: 'documents_review',
    component: <RatificationDocumentsReview />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'invoices.view_reviewdocumentsmanager'),
  },
];

export const pendingSignatureTabs = (user) => [
  {
    id: 'pending-signature',
    label: 'Pendiente de firma',
    path: 'pending-signature',
    component: <PendingSignatureManager />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'auth.view_signature'),
  },
];

export const buroAuthorizationTabs = (user) => [
  {
    id: 'buro-authorization',
    label: 'Formularios de Autorización',
    path: 'buro-authorization',
    component: <BuroAuthorizationManager />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'auth.view_buroauthorization'),
  },
];

export const creditLineEvaluationRequestsTabs = (user) => [
  {
    id: 'credit-line-evaluation-requests',
    label: 'Evaluaciones de líneas de crédito',
    path: 'credit-line-evaluation-requests',
    component: <CreditLineEvaluationRequests />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'evaluation_requests.view_creditlineevaluationrequest'),
  },
];

export const blacklistTabs = (user) => [
  {
    id: 'blacklist',
    label: 'Empresas con Potenciales Riesgo',
    path: 'blacklist',
    component: <Blacklist />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'backoffice_risk.view_riskblacklist'),
  },
];

export const riskBureausTabs = (user) => [
  {
    id: 'riskbureaus',
    label: 'Consultas Bureaus',
    path: 'bureaus',
    component: <RiskBureaus />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'bureaus.view_dicom'),
  },
];

export const riskRoutes = (user, country) => [
  {
    id: 'risk-pre-evaluations',
    label: 'Preevaluaciones',
    path: 'risk-preevaluations',
    icon: <Description />,
    component: <RiskPreEvaluations />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, riskEvaluationsTabs(user)),
  },
  {
    id: 'risk-evaluations',
    label: 'Evaluaciones',
    path: 'risk-evaluations',
    icon: <SendOutlined />,
    component: <RiskEvaluations />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, riskEvaluationsTabs(user)),
  },
  {
    id: 'credit-line-manager',
    label: 'Maestro de líneas',
    path: 'credit-line-manager',
    icon: <CreditScore />,
    component: <CreditLineManager />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, creditLineManagerTabs(user)),
  },
  {
    id: 'invoice-ratification',
    label: 'Ratificaciones',
    path: 'invoice-ratification',
    icon: <ManageHistory />,
    component: <Ratification />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, ratificationTabs(user)) && country && ['Chile'].includes(country.name),
  },
  {
    id: 'signature',
    label: 'Pendiente de firma',
    path: 'signature',
    icon: <GavelOutlined />,
    component: <PendingSignature />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, pendingSignatureTabs(user)) && country && ['Mexico'].includes(country.name),
  },
  {
    id: 'buro-authorization',
    label: 'Formularios de Autorización de Buró de Crédito',
    path: 'buro-authorization',
    icon: <ViewList />,
    component: <BuroAuthorization />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, buroAuthorizationTabs(user)) && country && ['Mexico'].includes(country.name),
  },
  {
    id: 'credit-line-evaluation-requests-manager',
    label: 'Evaluaciones de líneas de crédito',
    path: 'credit-line-evaluation-requests-manager',
    icon: <CreditScore />,
    component: <FingoPaper tabs={creditLineEvaluationRequestsTabs(user)} />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, creditLineEvaluationRequestsTabs(user)),
  },
  {
    id: 'blacklist',
    label: 'Blacklist',
    path: 'blacklist',
    icon: <ReportProblemRounded />,
    component: <BlacklistManager />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, blacklistTabs(user)),
  },
  {
    id: 'bureaus',
    label: 'Consultas Bureaus',
    path: 'bureaus',
    icon: <SearchOutlined />,
    component: <BureausManager />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, riskBureausTabs(user)) && country && ['Chile'].includes(country.name),
  },
];

export default riskRoutes;
